import React, { useState } from 'react';
import arrowUpIcon from "../assets/expand_circle_down_FILL0_wght300_GRAD0_opsz48.svg";
import arrowDownIcon from "../assets/expand_circle_down_FILL0_wght300_GRAD0_opsz48.svg";
import './TrendAnalysisCollapsibletext.css'
const CollapsibleText:React.FC<{children:String}> = ({children}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <button onClick={toggleCollapse} title='Collapsibletext' className='collapsibleArrowPosition'>
        <a>
        {isCollapsed ? <img src={arrowUpIcon} /> : <img src={arrowDownIcon} className='reverseImage'/>}
        </a>
      </button>
      <div>        
      {!isCollapsed && <div><hr className='collapsible-line-solid'/><p>{children}</p></div>}
      </div>
    </div>
  );
};

export default CollapsibleText;
