import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import dashboardIcon from "../assets/noun_dashboard_4164025 (3).svg";
import addRequestIcon from "../assets/add_box_FILL0_wght400_GRAD0_opsz48.svg";
import sideMenuSelect from "../shared/SideMenuSelect";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { isParentDomain } from "./Common";
import { parentDomain } from "./Constants";
const Sidebar = (): JSX.Element => {
  const user_Id = localStorage.getItem("userId");
  const userDetails=user_Id!=null?user_Id:"";  
  const isUserClient=!isParentDomain(userDetails,'@',parentDomain);
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    sideMenuSelect(path);
  }, []);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>, id: string) => {
    event.preventDefault();
    let divElement = document.getElementById(
      "sidebar-top-menu"
    ) as HTMLDivElement;
    if (divElement != null) {
      let subparentdiv = document.getElementById(
        "nav-side-menu"
      ) as HTMLDivElement;
      if (subparentdiv != null) {
        let allmenus = subparentdiv.getElementsByClassName(
          "side-bar-menu"
        ) as HTMLCollectionOf<Element>;
        for (let i = 0; i < allmenus.length; i++) {
          allmenus[i].classList.remove("active");
          allmenus[i].classList.add("not-active");
        }
      }
    }
    event.currentTarget.className = "row side-bar-menu active";
  };

  return (
    <div className="col-md-12" id="sidebar-top-menu">
      <div className="col-md-12" id="nav-side-menu">
        <div
          className="row side-bar-menu active"
          id="home"
          onClick={(event) => handleClick(event, "home")}
        >
          <Link to="/home">
            <span className="theme-color">
              <img src={dashboardIcon}></img>
            </span>
            <br />
            Dashboard
          </Link>
        </div>
        {!isUserClient?(
          <div
          className="row side-bar-menu"
          id="createrequest"
          onClick={(event) => handleClick(event, "new-request")}
        >
          <Link to="/createrequest">
            <span className="theme-color">
              <img src={addRequestIcon}></img>
            </span>
            <br />
            New Opportunity
          </Link>
        </div>
        ):("")
}     
        { <div
          className="row side-bar-menu"
          id="delegation"
          onClick={(event) => handleClick(event, "delegation")}
        >
          <Link to="/delegation">
            <span>
              <PersonAddAltIcon fontSize="large" />
            </span>
            <br />
            Delegation
          </Link>
        </div> }
      </div>
    </div>
  );
};

export default Sidebar;
