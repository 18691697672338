import React, { useEffect } from 'react';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { trendAnalysisInfoBox, openResourceRequirementsInfoBox, breachTimeLineInfoBox,
    averageFulfillmentRateInfoBox, onBoardingAverageInfoBox, filterInfoBox } from '../shared/Constants';

const HelpTour: React.FC = () => {
  useEffect(() => {
    const intro = introJs(); 
    intro.setOptions({
      steps: [
        {
          intro: 'Welcome to the tour of Resource Fulfillment Tracker!',
        },
        {
            element: '.filter',
            intro: `<h5>Global Filter</h5> ${filterInfoBox}`,
        },
        {
          element: '.trend-analysis-chart',
          intro: `<h5>Trend Analysis Chart</h5> ${trendAnalysisInfoBox}`,
        },
        {
            element: '.open-resource-requirments',
            intro: `<h5>Open Resource Requirement</h5> ${openResourceRequirementsInfoBox}`,
        },
        {
            element: '.breach-timeline-intro',
            intro: `<h5>Resources Request Breach Timeline</h5> ${breachTimeLineInfoBox}`,
        },
        {
            element: '.avg-rate-intro',
            intro: `<h5>Average Fulfillment Rate</h5> ${averageFulfillmentRateInfoBox}`,
        },
        {
            element: '.e-hire-average-css',
            intro: `<h5>Onboarding Average</h5> ${onBoardingAverageInfoBox}`,
        },        
    ]
    });
    intro.start();

    return () => {
      intro.exit(true);
    };  
  }, []);

  return <span className="target-element" style={{ display: 'none' }}></span>;
};

export default HelpTour;