export const msalConfig = {
  auth: {
    tenantId: process.env.REACT_APP_TENANT_ID ?? "",
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: `${
      "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID
    }`,
    redirectUri: process.env.REACT_APP_REDIRECT_URL ?? "",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE ?? ""], //["api://PUT_YOUR_WEBAPI_SCOPE_HERE/api.scope"],
};

export const commonRequest = {
  scopes: [
    "User.Read",
    process.env.REACT_APP_API_SCOPE ?? "",
  ],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint:
    "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group",
};
