import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Close";
import ProjectMappingDataService from "../services/projectmapping.service";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import { IUserDelegation } from "../types/projectmapping.type";
import editIcon from "../assets/edit_FILL0_wght400_GRAD0_opsz48.svg";
import deleteIcon from "../assets/delete_FILL0_wght400_GRAD0_opsz48.svg";
import saveIcon from "../assets/save_FILL0_wght300_GRAD0_opsz48.svg";
import "./ResourceResquestsDataGrid.css";
import ConfirmDialog from "../components/ConfirmDialog";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
type DelegationRequest = {
  initialRows: GridRowsProp;
  initialModesModel: GridRowModesModel;
  updateDelegation: any;
  delegationPage: boolean;
};

export default function DelegationsTable(props: DelegationRequest) {
  const [rows, setRows] = React.useState(props.initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    props.initialModesModel
  );
  const [confirmModalShow, setConfirmModalShow] = React.useState(false);
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };
  const [selectedid, setSelectedId] = React.useState({} as GridRowId);

  useEffect(() => {
    setRows(props.initialRows);
  }, [props.initialRows]);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const deletedRow = (id: GridRowId) => {
    setRows(rows.filter((row) => row.id !== id));
    if (props.delegationPage) {
      ProjectMappingDataService.revokeUserDelegation(id.toString())
        .then((response: any) => {
          props.updateDelegation();
          toast.success("User delegation revoked.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
            className: "successtoast",
          });
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else {
      ProjectMappingDataService.revokeOpportunityShare(parseInt(id.toString()))
        .then((response: any) => {
          toast.success("Opportunity delegation revoked.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
            className: "successtoast",
          });
          props.updateDelegation();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setSelectedId(id);
    setConfirmModalShow(true);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    let rowDetails: IUserDelegation = {
      id: newRow.id,
      user_delegation_id: newRow.user_delegation_id,
      access_level: newRow.access_level,
      shared_user_email_id: newRow.shared_user_email_id,
    };
    ProjectMappingDataService.updateUserDelegation(
      newRow.user_delegation_id,
      rowDetails
    )
      .then((response: any) => {
        toast.success("Details updated successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
          className: "successtoast",
        });
        props.updateDelegation();
      })
      .catch((e: Error) => {
        console.log(e);
      });
    return updatedRow;
  };

  const columns: GridColumns = [
    {
      field: "sl_no",
      headerName: "Sl No.",
      type: "string",
      editable: true,
      width: 50,
      headerClassName: "datagridheadercolor",
    },
    {
      field: "shared_user_to_email",
      headerName: "Email Id",
      type: "string",
      editable: false,
      flex: 1,
      headerClassName: "datagridheadercolor",
    },
    {
      valueOptions: ["Edit"], // "Read Only"],
      field: "access_level",
      headerName: "Access",
      type: "singleSelect",
      editable: true,
      flex: 1,
      headerClassName: "datagridheadercolor",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "datagridheadercolor",
      width: 80,
      cellClassName: "actions",
      renderHeader: () => null,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <a className="svg-icon" color="inherit">
                  <img src={saveIcon} />
                </a>
              }
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="svg-icon"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <a className="svg-icon" color="inherit">
                <img src={editIcon} />
              </a>
            }
            label="Edit"
            className="svg-icon"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <a className="svg-icon" color="inherit">
                <img src={deleteIcon} />
              </a>
            }
            className="svg-icon"
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "auto",
        width: "95%",
        "& .datagridheadercolor": {
          backgroundColor: "lightgrey",
        },
      }}
    >
      <DataGrid
        sx={{
          fontStyle: "Graphik",
          tabSize: "100%",
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "500 !important",
            color: "#0A273C",
            font: "normal normal normal 13px/17px Graphik",
          },
          ".MuiDataGrid-virtualScrollerContent": {
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "normal",
              color: "#0A273C",
              font: "normal normal normal 13px/17px Graphik",
            },
          },
        }}
        headerHeight={25}
        rowHeight={25}
        rows={rows.map((item, index) => ({
          sl_no: index + 1,
          id: item.user_delegation_id,
          ...item,
        }))}
        autoHeight
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooterPagination={true}
        hideFooter={true}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />

      <ConfirmDialog
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        deletedRow={deletedRow}
        selectedid={selectedid}
        title="Are you sure you want to delete this delegation?"
      ></ConfirmDialog>
    </Box>
  );
}
