import React, { useState, useEffect } from "react";
import "./CreateRequest.css";
import { Form, Col, Row } from "react-bootstrap";
import DelegationsTable from "./DelegationsTable";
import { GridRowModesModel, GridRowsProp } from "@mui/x-data-grid";
import {
  IUserDelegationAdd,
  IUser,
  SelectList,
  IOpportunityDelegationAdd,
} from "../types/projectmapping.type";
import ProjectMappingDataService from "../services/projectmapping.service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Select from "react-select";
import SetAPIAccessToken from "../shared/axios-common";
export default function Delegation(props: any) {
  SetAPIAccessToken();
  const [datafeed, setData] = useState<GridRowsProp>([]);
  const [modesmodel, setModesModel] = useState<GridRowModesModel>({});
  const [accessLevel, setAccessLevel] = useState<string>("Edit");
  const [users, setUsers] = useState<SelectList[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectList[]>([]);
  const UserTypes: { [key: string]: string } = {
    ACNLead: "ACNLead",
    ClientLead: "ClientLead",
  };
  const FilterClientUserType = "ClientLead";

  function handleSelect(data: any) {
    setSelectedOptions(data);
  }

  const getUserDetails = (excludeFunction: string) => {
    const user_Id = localStorage.getItem("tblUserId");
    let filter, oppty_id;
    if (excludeFunction === "userDelegation") {
      filter = user_Id !== null ? parseInt(user_Id) : 0;
      oppty_id = 0;
    } else if (excludeFunction === "opportunityDelegation") {
      filter = 0;
      oppty_id = props.opportunity_id;
    }
    ProjectMappingDataService.getUserDetails(
      filter,
      "",
      oppty_id
    )
      .then((response: any) => {
        const userType = localStorage.getItem("email")?.includes("accenture")
          ? UserTypes.ACNLead
          : UserTypes.ClientLead;
        const filteredUsers = response.data
          .filter((element: IUser) => {
            if (userType === FilterClientUserType) {
              return !element.email_id.includes("accenture");
            }
            return true;
          })
          .map((element: IUser) => ({
            value: element.email_id,
            label: element.email_id,
          }));
        setUsers(filteredUsers);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getUserDelegationDetails = () => {
    const user_Id = localStorage.getItem("tblUserId");
    const userType = localStorage.getItem("email")?.includes("accenture")
      ? UserTypes.ACNLead
      : UserTypes.ClientLead;
    ProjectMappingDataService.getUserDelegationDetails(
      user_Id != null ? parseInt(user_Id) : 0,
      userType
    )
      .then((delegationResponse: any) => {
        setData(delegationResponse.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getOpportunitySharedDetails = () => {
    const user_Id = localStorage.getItem("tblUserId");
    const userType = localStorage.getItem("email")?.includes("accenture")
      ? UserTypes.ACNLead
      : UserTypes.ClientLead;
    ProjectMappingDataService.getOpportunitySharedDetails(
      props.opportunity_id,
      user_Id,
      userType
    )
      .then((delegationResponse: any) => {
        setData(delegationResponse.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const excludeFunction = props.delegationPage === true ? "userDelegation" : "opportunityDelegation";
    getUserDetails(excludeFunction);
    if (props.delegationPage == true) getUserDelegationDetails();
    else getOpportunitySharedDetails();
  }, []);

  const updateDelegation = () => {
    const excludeFunction = props.delegationPage === true ? "userDelegation" : "opportunityDelegation";
    getUserDetails(excludeFunction);
    setSelectedOptions([]);
    if (props.delegationPage == true) getUserDelegationDetails();
    else getOpportunitySharedDetails();
  };

  const dropDownhandleChange = (event: any) => {
    setAccessLevel(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var selected = [];
    if (selectedOptions != undefined) {
      for (var i = 0; i < selectedOptions?.length; i++) {
        selected.push(selectedOptions[i].value);
      }
    }
    if (selectedOptions.length > 0) {
      const user_Id = localStorage.getItem("tblUserId");
      if (props.delegationPage == true) {
        const CreateDelegationRequest: IUserDelegationAdd = {
          user_id: user_Id != null ? parseInt(user_Id) : 0,
          access_level: accessLevel,
          delegated_to: selected,
        };

        ProjectMappingDataService.addUserDelegation(CreateDelegationRequest)
          .then((resp) => {
            toast.success("Delegation Submitted Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: 0,
              toastId: "my_toast",
              className: "successtoast",
            });
            setData([]);
            setModesModel({});
            const excludeFunction = props.delegationPage === true ? "userDelegation" : "opportunityDelegation";
            getUserDetails(excludeFunction);
            getUserDelegationDetails();
            setSelectedOptions([]);
          })
          .catch((resp) => {
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: 0,
              toastId: "my_toast",
              className: "failedtoast",
            });
            setData([]);
          });
      } else {
        const OpportunityShareRequest: IOpportunityDelegationAdd = {
          created_by: user_Id != null ? parseInt(user_Id) : 0,
          access_level: accessLevel,
          shared_to: selected,
          opportunity_id: props.opportunity_id,
        };

        ProjectMappingDataService.opportunityDelegation(OpportunityShareRequest)
          .then((resp) => {
            toast.success("Opportunity shared successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: 0,
              toastId: "my_toast",
              className: "successtoast",
            });
            setData([]);
            setModesModel({});
            const excludeFunction = props.delegationPage === true ? "userDelegation" : "opportunityDelegation";
            getUserDetails(excludeFunction);
            getOpportunitySharedDetails();
            setSelectedOptions([]);
          })
          .catch((resp) => {
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: 0,
              toastId: "my_toast",
              className: "failedtoast",
            });
            setData([]);
          });
      }
    } else {
      toast.error("Please select atleast one user to save", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        toastId: "my_toast",
        className: "failedtoast",
      });
    }
  };

  return (
    <div style={{ padding: "2%" }}>
      {props.delegationPage == true && (
        <div className="row create-request-title">
          Select Users for delegation
        </div>
      )}
      <div className="row">
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="toppadding">
            <Form.Label column sm={2} className="create-request-labels">
              Users
            </Form.Label>
            <Col sm={10}>
              <Select
                options={users}
                placeholder="Select Users"
                value={selectedOptions}
                onChange={handleSelect}
                isSearchable={true}
                isMulti
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="toppadding create-request-labels">
            <Form.Label column sm={2}>
              Access Level
            </Form.Label>
            <Col sm={4}>
              <Form.Select
                className="form-control"
                name="access_level"
                value={accessLevel}
                onChange={dropDownhandleChange}
              >
                {/* <option key={0} value="Read Only">
                  Read Only
                </option> */}
                <option key={0} value="Edit">
                  Edit
                </option>
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col className="toppadding" sm={{ offset: 5, span: 8 }}>
              <button type="submit" className="submit-button">
                Save
              </button>
            </Col>
          </Form.Group>
          {datafeed.length > 0 ? (
            <div className="toppadding">
              <DelegationsTable
                key={datafeed.length}
                initialRows={datafeed}
                initialModesModel={modesmodel}
                updateDelegation={updateDelegation}
                delegationPage={props.delegationPage}
              />
            </div>
          ) : (
            ""
          )}
        </Form>
        <ToastContainer />
      </div>
    </div>
  );
}
