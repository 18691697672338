import reactLogo from "../assets/Accenture-Logo.png";
import accountIcon from "../assets/account_circle_FILL0_wght300_GRAD0_opsz48.svg";
import helpIcon from "../assets/help_FILL0_wght300_GRAD0_opsz48.svg";
import React, { FC, useEffect, useState } from "react";
import HelpTour from "../components/HelpIconTour";
import { useMsal } from "@azure/msal-react";
function Navbar(props: any) {
  const [loggedInEmail, setEmail] = React.useState("");
  const [showTour, setShowTour] = useState(false);
  const handleHelpIconClick = () => {
    setShowTour(true);
  };
  const [userName, setUserName] = React.useState("");
  const { instance } = useMsal();
  const handleLogout = () => {
    localStorage.clear();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };
  useEffect(() => {
    const name = localStorage.getItem("name");
    if (name != null) setUserName(name);
    const email = localStorage.getItem("userId");
    if (email != null) setEmail(email);
  }, []);

  return (
    <div className="row top-nav-bar">
      <div className="col-md-2 logo-container center">
        <span>
          <img
            src={reactLogo}
            title="Accenture logo"
            alt="Accenture logo"
          ></img>
        </span>
      </div>
      <div className="col-md-7" style={{ alignSelf: "center" }}>
        <div className="request-fullfillment">
          <h4>Resource Fulfillment Tracker</h4>
        </div>
      </div>
      <div className="col-md-3">
        <div className="row">
          <div className="col-md-6 center">
            <a onClick={handleHelpIconClick}>
              <span className="theme-color">
                <img src={helpIcon}></img>
              </span>
              <br />
              <span className="nav-bar-label">Help</span>
            </a>
          </div>
          {showTour && <HelpTour fa-opencart={() => setShowTour(true)} />}
          <div className="col-md-6 center">
            <a
              href="#"
              id="userIconDropdown"
              role="button"
              data-bs-toggle="dropdown"
            >
              <span className="theme-color">
                <img src={accountIcon}></img>
              </span>
              <br />
              <span className="nav-bar-label">
                {userName} <br /> {loggedInEmail.match(/@(.*)\.com$/)?.[1].split('.').map((word, index) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join('.')} <br /> 
              </span>
            </a>
            <ul className="dropdown-menu">
              {/* <li>
                <a className="dropdown-item" href="#">
                  Profile
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Settings
                </a>
              </li> */}
              <li>
                <a className="dropdown-item" onClick={handleLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Navbar;
