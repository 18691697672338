import { Modal } from "react-bootstrap";
import * as React from "react";
import RequestTimeLine from "../components/RequestTimeline";
import timeLineIcon from "../assets/timeline_FILL0_wght200_GRAD0_opsz48.svg";
export default function AuditTrailResourceRequests(props: any) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="row" style={{ textAlign: "left" }}>
            <div className="col-md-2">
              <span>
                <img
                  src={timeLineIcon}
                  title="Timeline icon"
                  alt="Timeline icon"
                ></img>
              </span>
            </div>
            <div className="col-md-10">
              <h5>
                <span className="request-audit_trail-label">
                  Resource Audit Trail
                </span>
              </h5>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div
            className="row"
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
          >
            <div className="row" style={{ paddingTop: "1%" }}>
              <div className="col-md-2">
                <b>Request Title</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.request_title}
              </div>
              <div className="col-md-2">
                <b>Status</b> &nbsp; <span className="dot"></span>
              </div>
              <div className="col-md-4 capitalize">
                {props.projectRequestsTimeline?.resources != null
                  ? props.projectRequestsTimeline?.resources[0]?.status?.toLocaleLowerCase()?.replace("e-hire","onboarding")
                  : ""}                  
              </div>
            </div>
            <div className="row" style={{ paddingTop: "1%" }}>
              <div className="col-md-2">
                <b>ACN Lead</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.acn_lead_name}
              </div>
              <div className="col-md-2">
                <b>Primary Skills</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.resources != null
                  ? props.projectRequestsTimeline?.resources[0]?.primary_skill
                  : ""}
              </div>
            </div>
            <div className="row" style={{ paddingTop: "1%" }}>
              <div className="col-md-2">
                <b>Client Lead</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.client_lead_name}
              </div>
              <div className="col-md-2">
                <b>Secondary Skills</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.resources != null
                  ? props.projectRequestsTimeline?.resources[0]
                      ?.secondary_skills
                  : ""}
              </div>
            </div>
            <div className="row" style={{ paddingTop: "1%" }}>
              <div className="col-md-2">
                <b>Division</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.business_unit}
              </div>
              <div className="col-md-2">
                <b>Requested Fulfillment Date</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.resources != null &&
                props.projectRequestsTimeline?.resources.length > 0 &&
                props.projectRequestsTimeline?.resources[0]
                  ?.client_requested_date != null
                  ? new Date(
                      props.projectRequestsTimeline?.resources[0]?.client_requested_date
                    )
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/ /g, " ")
                  : ""}
              </div>
            </div>
            <div className="row" style={{ paddingTop: "1%" }}>
              <div className="col-md-2">
                <b>Created Date</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.resources != null &&
                props.projectRequestsTimeline?.resources?.length > 0 &&
                props.projectRequestsTimeline?.resources[0]?.created_on != null
                  ? new Date(
                      props.projectRequestsTimeline?.resources[0]?.created_on
                    )
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/ /g, " ")
                  : ""}
              </div>
              <div className="col-md-2">
                <b>Agreed Fulfillment Date</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.resources != null &&
                props.projectRequestsTimeline?.resources?.length > 0 &&
                props.projectRequestsTimeline?.resources[0]?.acn_agreed_fullfilment_date != null
                  ? new Date(
                      props.projectRequestsTimeline?.resources[0]?.acn_agreed_fullfilment_date
                    )
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/ /g, " ")
                  : ""}
              </div>
            </div>
          </div>

          <div className="request-audit_trail" style={{ marginTop: "2%" }}>
            <RequestTimeLine
              title="Resource Timeline"
              status_history={
                props.projectRequestsTimeline?.resources != null &&
                props.projectRequestsTimeline?.resources?.length > 0
                  ? props.projectRequestsTimeline?.resources[0]?.status_history
                  : null
              }
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
