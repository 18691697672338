import React from "react";
import { Modal } from "react-bootstrap";
import CreateRequest from "./CreateRequest";
import IProjectMappingData from "../types/projectmapping.type";

export default function ProjectMappingRequestModel(props: any) {
  const modalClose = () => {
    props.onHide();
  };
  const updateRequest = (updatedRequest: IProjectMappingData) => {
    props.updateRequest(updatedRequest);
  };
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Opportunity
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateRequest
          initialData={props.datafeed}
          formType={props.formType}
          modalClose={modalClose}
          updateRequest={updateRequest}
          title="Update Opportunity"
        />
      </Modal.Body>
    </Modal>
  );
}
