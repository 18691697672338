import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: false,
      text: "Trend Analysis",
    },
    legend:{
      position:"right"
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

// Label formatter function
const formatter = (value, ctx) => {
  if (value == 0) {
    return "";
  }
  return value;
};

export default function TrendAnalysis(props) {
  var timeline = props.trendAnalysisData?.map(x => x.timeline);
  var openStatus = props.trendAnalysisData?.map(x => x.open);
  var notFulfilledStatus = props.trendAnalysisData?.map(x => x.not_fulfilled);
  var cancelledStatus = props.trendAnalysisData?.map(x => x.cancelled);
  var breachedStatus = props.trendAnalysisData?.map(x => x.breached);
  var fulfilledStatus = props.trendAnalysisData?.map(x => x.fulfilled);
  const data = {
    labels: timeline,
    datasets: [
      {
        label: "Open ("+openStatus?.reduce(function (a, b) {return a + b;}, 0)+")",
        data: openStatus,
        backgroundColor: "#1E90FF",
        datalabels: {
          color: "#000000",
          formatter: formatter,
        },
        barThickness: 70
      },
      {
        label: "Not Fulfilled ("+notFulfilledStatus?.reduce(function (a, b) {return a + b;}, 0)+")",
        data: notFulfilledStatus,
        backgroundColor: "#FE7800",
        datalabels: {
          color: "#000000",
          formatter: formatter,
        },
        barThickness: 70
      },
      {
        label: "Cancelled ("+cancelledStatus?.reduce(function (a, b) {return a + b;}, 0)+")",
        data: cancelledStatus,
        backgroundColor: "#96968BCC",
        datalabels: {
          color: "#000000",
          formatter: formatter,
        },
        barThickness: 70
      },
      {
        label: "Breached ("+breachedStatus?.reduce(function (a, b) {return a + b;}, 0)+")",
        data: breachedStatus,
        backgroundColor: "#FFEA04",
        datalabels: {
          color: "#000000",
          formatter: formatter,
        },
        barThickness: 70
      },
      {
        label: "Fulfilled ("+fulfilledStatus?.reduce(function (a, b) {return a + b;}, 0)+")",
        data: fulfilledStatus,
        backgroundColor: "#00F0A2",
        datalabels: {
          color: "#000000",
          formatter: formatter,
        },
        barThickness: 70
      },
    ],
  };
  return (
    <Bar
      options={options}
      plugins={[ChartDataLabels]}
      height={50}
      data={data}
    />
  );
}
