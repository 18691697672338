import http from "axios";
import IProjectMappingData, {
  OpenRequestStatusCount,
  IFilterRequestData,
  ILoginViewModel,
  ILoginResponse,
  ITrendAnalysis,
  IFilterDataDetails,
  IDashboardViewModel,
  IRequestDetailsHistoryData,
  IServiceTypeMasterData,
  IExperienceMasterData,
  IResourceStatusMasterData,
  IViewDetailsModel,
  AverageFulfillmentRate,
  IUser,
  IUserDelegation,
} from "../types/projectmapping.type";
import { globalLoading } from "react-global-loading";

class ProjectMappingDataService {
  getAll = async (data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<Array<IProjectMappingData>>(
      `/GetOpportunityDetails`,
      data
    );
    globalLoading.hide();
    return result;
  };

  get = async (id: string, data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<IProjectMappingData>(
      `/GetOpportunityDetailsById/${id}`,
      data
    );
    globalLoading.hide();
    return result;
  };

  create = async (data: IProjectMappingData) => {
    globalLoading.show();
    var result = await http.post<IProjectMappingData>("/AddOpportunity", data);
    globalLoading.hide();
    return result;
  };

  update = async (data: IProjectMappingData, id: any) => {
    globalLoading.show();
    var result = await http.put<any>(`/UpdateOpportunity/${id}`, data);
    globalLoading.hide();
    return result;
  };

  delete = async (id: any) => {
    globalLoading.show();
    var result = await http.delete<any>(`/DeleteOpportunity/${id}`);
    globalLoading.hide();
    return result;
  };

  getFilterData = async (data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<IFilterDataDetails>(
      `/GetFilterDataMasterData`,
      data
    );
    globalLoading.hide();
    return result;
  };

  getDashboardDetailsByFilter = async (data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<IDashboardViewModel>(
      "/GetDashboardDataDetails",
      data
    );
    globalLoading.hide();
    return result;
  };

  getTrendAnalysisData = async (data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<IDashboardViewModel>(
      `/GetTrendAnalysisData`,
      data
    );
    globalLoading.hide();
    return result;
  };

  getEHireAverageData = async (data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<number>(`/GetEhireAverageData`, data);
    globalLoading.hide();
    return result;
  };

  getAverageFulfillmentData = async (data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<AverageFulfillmentRate>(
      `/GetAverageFulfillmentData`,
      data
    );
    globalLoading.hide();
    return result;
  };

  getViewDetailsData = async (data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<IViewDetailsModel>(`/ViewDetails`, data);
    globalLoading.hide();
    return result;
  };

  getRequestTimeline = async (id: string) => {
    globalLoading.show();
    var result = await http.get<IRequestDetailsHistoryData>(
      `/OpportunityStatus/${id}`
    );
    globalLoading.hide();
    return result;
  };

  getResourceTimeline = async (requestKey: string, resourceKey: string) => {
    globalLoading.show();
    var result = await http.get<Array<IRequestDetailsHistoryData>>(
      `/ResourceStatus/${requestKey}/${resourceKey}`
    );
    globalLoading.hide();
    return result;
  };

  deleteResource = async (id: any) => {
    globalLoading.show();
    var result = await http.delete<any>(`/DeleteResourceDetails/${id}`);
    globalLoading.hide();
    return result;
  };

  updateResource = async (id: any, data: any) => {
    globalLoading.show();
    var result = await http.put<any>(`/UpdateResourceDetails/${id}`, data);
    globalLoading.hide();
    return result;
  };

  getRequestStatusDetails = async (data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<OpenRequestStatusCount>(
      `/GetOpenOpportunityStatusCount`,
      data
    );
    globalLoading.hide();
    return result;
  };

  getResourceStatusDetails = async (data: IFilterRequestData) => {
    globalLoading.show();
    var result = await http.post<OpenRequestStatusCount>(
      `/GetOpenResourceStatusCount`,
      data
    );
    globalLoading.hide();
    return result;
  };

  getExperienceMasterData = async () => {
    globalLoading.show();
    var result = await http.get<Array<IExperienceMasterData>>(
      "/ExperienceBandMasterData"
    );
    globalLoading.hide();
    return result;
  };

  getServiceTypeMasterData = async () => {
    globalLoading.show();
    var result = await http.get<Array<IServiceTypeMasterData>>(
      "/ServiceTypeMasterData"
    );
    globalLoading.hide();
    return result;
  };

  getResourceStatusMasterData = async () => {
    globalLoading.show();
    var result = await http.get<Array<IResourceStatusMasterData>>(
      "/ResourceStatusMasterData"
    );
    globalLoading.hide();
    return result;
  };

  login = async (data: ILoginViewModel) => {
    globalLoading.show();
    var result = await http.post<ILoginResponse>("/Login", data);
    globalLoading.hide();
    return result;
  };

  upSertUser = async (data: ILoginViewModel) => {
    globalLoading.show();
    var result = await http.post<ILoginResponse>("/UpsertUser", data);
    globalLoading.hide();
    return result;
  };

  revokeUserDelegation = async (id: any) => {
    globalLoading.show();
    var result = await http.delete<any>(
      `/RevokeUserDelegation?userDelegationId=${id}`
    );
    globalLoading.hide();
    return result;
  };

  updateUserDelegation = async (id: any, data: any) => {
    globalLoading.show();
    var result = await http.put<any>(
      `/UpdateUserDelegation?userDelegationId=${id}`,
      data
    );
    globalLoading.hide();
    return result;
  };

  addUserDelegation = async (data: any) => {
    globalLoading.show();
    var result = await http.post<boolean>("/AddUserDelegation", data);
    globalLoading.hide();
    return result;
  };

  getUserDetails = async (userId?: any, data?: string, oppty_id?: any) => {
    globalLoading.show();
    var result = await http.get<IUser>(
      `/GetUsers?userId=${userId}&searchText=${data}&opptyId=${oppty_id}`
    );
    globalLoading.hide();
    return result;
  };

  getUserDelegationDetails = async (data?: any, userType?: any) => {
    globalLoading.show();
    var result = await http.get<IUserDelegation>(
      `/GetUserDelegationDetails?userId=${data}&userType=${userType}`
    );
    globalLoading.hide();
    return result;
  };

  addComment = async (data: any) => {
    globalLoading.show();
    var result = await http.post<any>("/AddComment", data);
    globalLoading.hide();
    return result;
  };

  opportunityDelegation = async (data: any) => {
    globalLoading.show();
    var result = await http.post<boolean>("/OpportunityDelegation", data);
    globalLoading.hide();
    return result;
  };

  getOpportunitySharedDetails = async (data?: any, userId?: any, userType?: any) => {
    globalLoading.show();
    var result = await http.get<IUserDelegation>(
      `/OpportunitySharedUserDetails?opportunityId=${data}&userId=${userId}&userType=${userType}`
    );
    globalLoading.hide();
    return result;
  };

  revokeOpportunityShare = async (id: number) => {
    globalLoading.show();
    var result = await http.delete<any>(`/RevokeDelegatedOpportunity/${id}`);
    globalLoading.hide();
    return result;
  };

  opportunityClone = async (data: any) => {
    globalLoading.show();
    var result = await http.post<boolean>("/OpportunityClone", data);
    globalLoading.hide();
    return result;
  };

  resourceClone = async (data: any) => {
    globalLoading.show();
    var result = await http.post<boolean>("/ResourceClone", data);
    globalLoading.hide();
    return result;
  };

  verifyUserExistence = async (data: any, userType: string) => {
    const result = await http.get<any[]>(`/GetUsersByType?userType=${userType}&searchText=${data}`);
    return result.data.length > 0;
  };  
}

export default new ProjectMappingDataService();
