import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Close";
import AddCommentIcon from "@mui/icons-material/AddCommentOutlined";
import ProjectMappingDataService from "../services/projectmapping.service";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloneConfirmDialog from "../components/CloneConfirmDialog";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  IResourceDetails,
  IStatusHistoryDetails,
  IExperienceMasterData,
  IServiceTypeMasterData,
  IResourceStatusMasterData,
  AddCommentModel,
  IResourceCloneRequest,
} from "../types/projectmapping.type";
import AuditTrailResourceRequests from "./AuditTrailResourceRequest";
import getCurrentDate, { isParentDomain } from "../shared/Common";
import editIcon from "../assets/edit_FILL0_wght400_GRAD0_opsz48.svg";
import deleteIcon from "../assets/delete_FILL0_wght400_GRAD0_opsz48.svg";
import visibilityIcon from "../assets/visibility_FILL0_wght300_GRAD0_opsz48.svg";
import saveIcon from "../assets/save_FILL0_wght300_GRAD0_opsz48.svg";
import StatusChangeModal from "../components/StatusChangeModal";
import "./ResourceResquestsDataGrid.css";
import ConfirmDialog from "../components/ConfirmDialog";
import AddComment from "../components/AddComment";
import getFormattedDate, { apiFormattedDate } from "../shared/DateFormatter";
import { parentDomain } from "../shared/Constants";
type CreateNewRequest = {
  initialRows: GridRowsProp;
  initialModesModel: GridRowModesModel;
  requestId: number;
  updateResource: (Row: IResourceDetails) => void;
  deleteResource: (id: GridRowId) => void;
  requestStatus: string | undefined;
  ResetRequestDetails: () => void;
};

export default function ResourceDataGrid(props: CreateNewRequest) {
  const user_Id = localStorage.getItem("tblUserId");
  const userEmailid=localStorage.getItem("userId");
  const [rows, setRows] = React.useState(props.initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    props.initialModesModel
  );
  const userDetails=userEmailid!=null?userEmailid:"";  
  const isUserClient=!isParentDomain(userDetails,'@',parentDomain);
  const [resourceAuditRequests, setResourceAuditRequests] = React.useState([]);
  const [ResourceAuditTrailModalShow, setResourceAuditTrailModalShow] =
    React.useState(false);
  const [statusChangeModalShow, setStatusChangeModalShow] =
    React.useState(false);
  const [confirmModalShow, setConfirmModalShow] = React.useState(false);
  const [resourceCloneModalShow, setResourceCloneModalShow] =
    React.useState(false);
  const [commentModalShow, setCommentModalShow] = React.useState(false);
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };
  const [statusModifiedRow, setStatusModifiedRow] = React.useState(
    {} as GridRowModel
  );
  const [selectedid, setSelectedId] = React.useState({} as GridRowId);
  const [selectedStatusValue, setSelectedStatusValue] = React.useState(
    {} as string
  );
  const [experienceMasterData, setExperienceMasterData] = React.useState([]);
  const [serviceTypeMasterData, setServiceTypeMasterData] = React.useState([]);
  const [resourceStatusMasterData, setResourceStatusMasterData] =
    React.useState<IResourceStatusMasterData[]>([]);

  useEffect(() => {
    setRows(props.initialRows);
    getExperienceMasterData();
    getServiceTypeMasterData();
    getResourceStatusMasterData();
  }, [props.initialRows]);

  const getExperienceMasterData = () => {
    ProjectMappingDataService.getExperienceMasterData()
      .then((response: any) => {
        setExperienceMasterData(
          response.data.map((element: IExperienceMasterData) => element.band)
        );
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getServiceTypeMasterData = () => {
    ProjectMappingDataService.getServiceTypeMasterData()
      .then((response: any) => {
        setServiceTypeMasterData(
          response.data.map((element: IServiceTypeMasterData) => element.type)
        );
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getResourceStatusMasterData = () => {
    ProjectMappingDataService.getResourceStatusMasterData()
      .then((response: any) => {
        setResourceStatusMasterData(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const deletedRow = (id: GridRowId) => {
    setRows(rows.filter((row) => row.id !== id));
    ProjectMappingDataService.deleteResource(id.toString())
      .then((response: any) => {
        props.ResetRequestDetails();
        props.deleteResource(id);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const cloneRow = (id: GridRowId) => {
    const user_Id = localStorage.getItem("tblUserId");
    const resourceClone: IResourceCloneRequest = {
      created_by: user_Id != null ? parseInt(user_Id) : 0,
      resource_id: parseInt(id.toString()),
    };
    ProjectMappingDataService.resourceClone(resourceClone)
      .then((response: any) => {
        props.ResetRequestDetails();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const addComment = (id: any, comments: any) => {
    const user_Id = localStorage.getItem("tblUserId");
    var comment = {
      id: id,
      comments: comments,
      created_by: user_Id != null ? parseInt(user_Id) : 0,
      comment_type: "resource",
    };
    ProjectMappingDataService.addComment(comment)
      .then((response: any) => {
        console.log("addComment call completed");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setSelectedId(id);
    setConfirmModalShow(true);
  };

  const handleAddCommentClick = (id: GridRowId) => () => {
    setSelectedId(id);
    setCommentModalShow(true);
  };

  const handleCloneClick = (id: GridRowId) => () => {
    console.log(id);
    setSelectedId(id);
    setResourceCloneModalShow(true);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  let comments = "";
  const updatedstatusAndComments = (
    newRow: GridRowModel,
    id: GridRowId,
    comment: string
  ) => {
    comments = comment;
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    processRowUpdate(newRow);
  };
  function afterupdatecomplete() {
    console.log(rows);
  }
  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    let originalRow = rows.filter((x) => x.id == newRow.id)[0];
    let isStatusUpdated = false;
    if (newRow.status != originalRow.status) {
      isStatusUpdated = true;
    }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    const email_id = localStorage.getItem("email");
    let statusHistory: IStatusHistoryDetails[] = [];
    if (isStatusUpdated) {
      statusHistory = [
        {
          id: 0,
          comments: comments,
          created_user_id: user_Id != null ? parseInt(user_Id) : 0,
          status: newRow.status,
          created_on: getCurrentDate().toString(),
          status_update_date:
            newRow.status_update_date == null
              ? getCurrentDate().toString()
              : apiFormattedDate(newRow.status_update_date),
        },
      ];
    }
    let rowDetails: IResourceDetails = {
      id: newRow.id,
      acn_agreed_fullfilment_date: apiFormattedDate(
        newRow.acn_agreed_fullfilment_date
      ),
      client_requested_date: apiFormattedDate(newRow.client_requested_date),
      experience_band: newRow.experience_band,
      primary_skill: newRow.primary_skill,
      resource_name: newRow.resource_name,
      secondary_skills: newRow.secondary_skills,
      status: newRow.status,
      service_type: newRow.service_type,
      created_user_id: user_Id != null ? parseInt(user_Id) : 0,
      updated_user_id: user_Id != null ? parseInt(user_Id) : 0,
      created_on: apiFormattedDate(newRow.created_on),
      status_history: statusHistory,
      //isempty:newRow.isempty
    };
    ProjectMappingDataService.updateResource(newRow.id, rowDetails)
      .then((response: any) => {
        props.updateResource(rowDetails);
      })
      .catch((e: Error) => {
        console.log(e);
      });
    return updatedRow;
  };
  const handleHistoryClick = (id: GridRowId) => () => {
    ProjectMappingDataService.getResourceTimeline(
      props.requestId.toString(),
      id.toString()
    )
      .then((response: any) => {
        setResourceAuditRequests(response.data);
        setResourceAuditTrailModalShow(true);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleStatusChange = (
    event: any,
    id: GridRowId,
    rowdata: GridRowModel
  ) => {
    event.preventDefault();
    comments = "";
    console.log(id);
    setSelectedId(id);
    setSelectedStatusValue(event.target.value);
    setStatusModifiedRow(rowdata);
    setStatusChangeModalShow(true);
  };

  const columns: GridColumns = [
    {
      field: "slno",
      headerName: "Sl No.",
      type: "string",
      editable: true,
      width: 50,
      headerClassName: "datagridheadercolor",
    },
    {
      field: "historypopup",
      width: 25,
      headerClassName: "datagridheadercolor",
      renderHeader: () => null,
      renderCell: (params) => {
        return (
          <a
            className="svg-icon"
            color="inherit"
            onClick={handleHistoryClick(params.row.id)}
          >
            <img src={visibilityIcon} />
          </a>
        );
      },
    },
    {
      field: "primary_skill",
      headerName: "Primary Skill",
      type: "string",
      editable: true,
      flex: 1,
      headerClassName: "datagridheadercolor",
    },
    {
      field: "secondary_skills",
      headerName: "Secondary Skills",
      type: "string",
      editable: true,
      flex: 1,
      headerClassName: "datagridheadercolor",
    },
    {
      valueOptions: experienceMasterData,
      field: "experience_band",
      headerName: "Experience Band",
      type: "singleSelect",
      editable: true,
      flex: 1,
      headerClassName: "datagridheadercolor",
    },
    {
      valueOptions: serviceTypeMasterData,
      field: "service_type",
      headerName: "Service Type",
      type: "singleSelect",
      editable: true,
      flex: 1,
      headerClassName: "datagridheadercolor",
    },
    {
      field: "client_requested_date",
      headerName: "Requested Fulfillment Date",
      type: "date",
      editable: true,
      flex: 1,
      headerClassName: "datagridheadercolor",
      valueGetter: ({ value }) => getFormattedDate(value),
    },
    {
      field: "acn_agreed_fullfilment_date",
      headerName: "Agreed Fulfillment Date",
      type: "date",
      editable: true,
      flex: 1,
      headerClassName: "datagridheadercolor",
      valueGetter: ({ value }) => getFormattedDate(value),
    },
    {
      field: "actual_fullfilment_date",
      headerName: "Actual Fulfilled Date",
      type: "date",
      editable: false,
      flex: 1,
      headerClassName: "datagridheadercolor",
      valueGetter: ({ value }) => getFormattedDate(value),
    },
    {
      field: "resource_name",
      headerName: "Resource Name",
      type: "string",
      editable: true,
      headerClassName: "datagridheadercolor",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      editable: false,
      width: 150,
      headerClassName: "datagridheadercolor",
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.row.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return (
            <div className="dropdown-select">
              <select
                value={params.row.status}
                onChange={(event) =>
                  handleStatusChange(event, params.row.id, params.row)
                }
                className="statusdropdown statustextcapitalize"
              >
                {resourceStatusMasterData &&
                  resourceStatusMasterData.map((optionsData, i) => (
                    <option
                      key={optionsData.status}
                      className="statustextcapitalize"
                      value={optionsData.status?.toString()}
                    >
                      {optionsData.status?.toLowerCase()?.replace("e-hire","onboarding")}
                    </option>
                  ))}
              </select>
            </div>
          );
        } else {
          return (
            <div className="statustextcapitalize">
              {params.row.status?.toLowerCase()?.replace("e-hire","onboarding")}
            </div>
          );
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",      
      headerClassName: "datagridheadercolor",
      width: 120,
      cellClassName: "actions",
      renderHeader: () => null,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (props.requestStatus == "CLOSED") {
          return [
            <GridActionsCellItem
              icon={
                <a className="svg-icon" color="inherit">
                  <img src={editIcon} />
                </a>
              }
              label="Edit"
              className="tesxtgreyout disabled svg-icon"
              color="inherit"
              disabled={true}
            />,
            <GridActionsCellItem
              icon={
                <a className="svg-icon" color="inherit">
                  <img src={deleteIcon} />
                </a>
              }
              label="Delete"
              color="inherit"
              className="tesxtgreyout disabled svg-icon"
              disabled={true}
            />,
          ];
        }
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<ContentCopyIcon />}
              className="svg-icon"
              label="Clone"
              onClick={handleCloneClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={
                <a className="svg-icon" color="inherit">
                  <img src={saveIcon} />
                </a>
              }
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="svg-icon"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<AddCommentIcon />}
              className="svg-icon"
              label="Comment"
              onClick={handleAddCommentClick(id)}
              color="inherit"
            />,
          ];
        }        
        return [
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            className="svg-icon"
            label="Clone"
            onClick={handleCloneClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <a className="svg-icon" color="inherit">
                <img src={editIcon} />
              </a>
            }
            label="Edit"
            className="svg-icon"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <a className="svg-icon" color="inherit">
                <img src={deleteIcon} />
              </a>
            }
            className="svg-icon"
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<AddCommentIcon />}
            className="svg-icon"
            label="Comment"
            onClick={handleAddCommentClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "auto",
        width: "96%",
        "& .datagridheadercolor": {
          backgroundColor: "lightgrey",
        },
      }}
    >
      <DataGrid
        sx={{
          fontStyle: "Graphik",
          tabSize: "100%",
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "500 !important",
            color: "#0A273C",
            font: "normal normal normal 13px/17px Graphik",
          },
          ".MuiDataGrid-virtualScrollerContent": {
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "normal",
              color: "#0A273C",
              font: "normal normal normal 13px/17px Graphik",
            },
          },
        }}
        headerHeight={25}
        rowHeight={25}
        rows={rows.map((item, index) => ({ slno: index + 1, ...item }))}
        autoHeight
        columns={columns}
        editMode="row"
        // isCellEditable={(params)=>{if(params.colDef.field=="status" && props.formType!="Edit") return false;else return true;}}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooterPagination={true}
        hideFooter={true}
        columnVisibilityModel={{
          actions:!isUserClient
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
      <AuditTrailResourceRequests
        projectRequestsTimeline={resourceAuditRequests}
        show={ResourceAuditTrailModalShow}
        onHide={() => setResourceAuditTrailModalShow(false)}
      />
      <StatusChangeModal
        show={statusChangeModalShow}
        onHide={() => setStatusChangeModalShow(false)}
        updatedstatusAndComments={updatedstatusAndComments}
        statusModifiedRowDetails={statusModifiedRow}
        selectedStatusValue={selectedStatusValue}
        selectedid={selectedid}
      />
      <ConfirmDialog
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        deletedRow={deletedRow}
        selectedid={selectedid}
        title="Are you sure you want to delete this resource?"
      ></ConfirmDialog>

      <CloneConfirmDialog
        show={resourceCloneModalShow}
        onHide={() => setResourceCloneModalShow(false)}
        cloneRow={cloneRow}
        selectedid={selectedid}
        title="Do you want to clone this resource?"
      ></CloneConfirmDialog>

      <AddComment
        show={commentModalShow}
        onHide={() => setCommentModalShow(false)}
        selectedid={selectedid}
        addComment={addComment}
        title="Add New Comment"
      ></AddComment>
    </Box>
  );
}
