import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import breachTimelineUserIcon from "../assets/group_FILL0_wght400_GRAD0_opsz48.svg";
import CollapsibleText from "./TrendAnalysisCollapsibletext";
import { breachTimeLineInfoBox } from "../shared/Constants";
import './BreachTimelineChart.css';
const ColorlibConnector = styled(StepConnector)(({}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12.5,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    borderRadius: 1,
    backgroundColor: "#DCB4FF",
  },
}));

const ColorlibStepIconRoot = styled("div")<{}>(({}) => ({
  zIndex: 1,
  color: "##000000",
  width: 70,
  height: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#DCB4FF",
}));

function ColorlibStepIcon(props: StepIconProps) {
  const icons: { [index: string]: string } = {
    1: "< -10 days",
    2: "-10 days",
    3: "-5 days",
    4: "-1 day",
    5: "Today",
    6: "+1 day",
    7: "+5 days",
    8: "+10 days",
    9: "> 10 days",
  };

  return (
    <ColorlibStepIconRoot>{icons[String(props.icon)]}</ColorlibStepIconRoot>
  );
}

export default function BreachTimelineChart(props: any) {
  return (
    <div>
      <div className="row margin-top-2">
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={1}
            connector={<ColorlibConnector />}
          >
            {props.steps &&
              props.steps.map((data: any, index: any) => (
                <Step key={index}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <div className="row" style={{ alignItems: "center" }}>
                      <div
                        className="col-md-6 theme-color"
                        style={{ textAlign: "right", padding: "0%" }}
                      >
                        <span>
                          <img src={breachTimelineUserIcon}></img>
                        </span>
                      </div>
                      <div
                        className="col-md-6 theme-color"
                        style={{ textAlign: "left", padding: "2% 0% 0% 5%" }}
                      >
                        <Link
                          to="/requests"
                          state={{
                            agreed_fulfillment_range: data.timeline,
                            status: "Open",
                          }}
                          className="anchor"
                        >
                          {data.count}
                        </Link>
                      </div>
                    </div>
                  </StepLabel>
                </Step>
              ))}
          </Stepper>
        </Stack>
      </div>
      <div className="row breach-request-line"></div>
      <div className="row breach-request-disclaimer-margin-bottom">        
        <CollapsibleText>{breachTimeLineInfoBox}</CollapsibleText>
      </div>
    </div>
  );
}
