import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import CreateRequest from "./components/CreateRequest";
import IProjectMappingData from "../src/types/projectmapping.type";
import CollapsibleTable from "./components/Requests";
import Delegation from "./components/Delegation";
function AppRoutes() {
  const [datafeed, setData] = React.useState<IProjectMappingData>(
    {} as IProjectMappingData
  );
  const [delegationPage, setDelegationPage] = React.useState<boolean>(true);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/requests" element={<CollapsibleTable />} />
      <Route
        path="/delegation"
        element={<Delegation delegationPage={delegationPage} />}
      />
      <Route
        path="/createrequest"
        element={<CreateRequest initialData={datafeed} />}
      />
    </Routes>
  );
}
export default AppRoutes;
