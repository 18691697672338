import { Modal } from "react-bootstrap";
import React from "react";

const CloneConfirmDialog = (props: any) => {
  const modalClose = () => {
    props.onHide();
  };
  const handleCloneEvent = () => {
    props.cloneRow(props.selectedid);
    modalClose();
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {props.title}

        <div className="row center margin-2">
          <div className="col-md-2">
            <button
              type="submit"
              className="submit-button"
              id="clone"
              onClick={handleCloneEvent}
            >
              Clone
            </button>
          </div>
          <div className="col-md-2">
            <button
              type="button"
              className="btn-secondary-color"
              onClick={modalClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CloneConfirmDialog;
