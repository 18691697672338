import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import Delegation from "../components/Delegation";
const ShareOpportunity = (props: any) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="row" style={{ textAlign: "left" }}>
            <h5 className="headings">{props.title} </h5>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Delegation
            delegationPage={false}
            opportunity_id={props.selectedid}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareOpportunity;
