export default function getFormattedDate(date: any) {
  return (
    date &&
    new Date(date)
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, " ")
  );
}

export function apiFormattedDate(date: any) {
  return date && new Date(date).toLocaleDateString();
}
