import axios, { AxiosRequestConfig } from "axios";
import { globalLoading } from "react-global-loading";
export default function SetAPIAccessToken() {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    // if (!config.headers.has("Access-Control-Allow-Origin")) {
    //   config.headers["Access-Control-Allow-Origin"] = "*";
    // }
    // if (!config.headers.has("X-XSS-Protection")) {
    //   config.headers["X-XSS-Protection"] = "1; mode=block";
    // }
    // if (!config.headers.has("X-Content-Type-Options")) {
    //   config.headers["X-Content-Type-Options"] = "nosniff";
    // }

    // if (!config.headers.has("X-Frame-Options")) {
    //   config.headers["X-Frame-Options"] = "SAMEORIGIN";
    // }
    // if (!config.headers.has("Content-Security-Policy")) {
    //   config.headers["Content-Security-Policy"] =
    //     "upgrade-insecure-requests; base-uri 'self'; frame-ancestors 'self'; form-action 'self'; object-src 'none';";
    // }
    // if (!config.headers.has("Referrer-Policy")) {
    //   config.headers["Referrer-Policy"] = "strict-origin-when-cross-origin";
    // }
    // if (!config.headers.has("Strict-Transport-Security")) {
    //   config.headers["Strict-Transport-Security"] =
    //     "max-age=31536000; includeSubDomains; preload";
    // }
    // if (!config.headers.has("Permissions-Policy")) {
    //   config.headers["Permissions-Policy"] =
    //     "accelerometer=(self), camera=(self), geolocation=(self), gyroscope=(self), magnetometer=(self), microphone=(self), payment=(self), usb=(self)";
    // }
    // if (!config.headers.has("Feature-Policy")) {
    //   config.headers["Feature-Policy"] = "none";
    // }
    // if (!config.headers.has("X-Permitted-Cross-Domain-Policies")) {
    //   config.headers["X-Permitted-Cross-Domain-Policies"] = "none";
    // }
    // if (!config.headers.has("Expect-CT")) {
    //   config.headers["Expect-CT"] = "max-age=0";
    // }
    // if (!config.headers.has("Cache-Control")) {
    //   config.headers["Cache-Control"] = "no-cache, no-store, must-revalidate";
    // }
    return config;
  });
}

export function CreateAxios() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.response.use(undefined, function (error) {
    console.log(error);
    if (error.response.status === 401) {
      globalLoading.hide();
      return Promise.reject(error);
    }
  });
}
