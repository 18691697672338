import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import ProjectMappingDataService from "../services/projectmapping.service";
import Box from "@mui/material/Box";
import ProjectMappingRequestModel from "./ResourcesModal";
import AuditTrailProjectRequest from "./AuditTrailProjectRequest";
import IProjectMappingData, {
  IRequestDetailsHistoryData,
  IFilterDataDetails,
  IResourceDetails,
  OpenRequestStatusCount,
  IFilterRequestData,
  OpenResourceRequest,
  IOpportunityCloneRequest,
  IResourceCloneRequest,
} from "../types/projectmapping.type";
import { GridRowId, GridRowModesModel } from "@mui/x-data-grid";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Filter from "../components/Filter";
import ResourceDataGrid from "./ResourceResquestsDataGrid";
import { TablePagination } from "@mui/material";
import { Link } from "react-router-dom";
import openResourceIcon from "../assets/groups_FILL0_wght200_GRAD0_opsz48.svg";
import materialPeopleIcon from "../assets/Icon material-people.svg";
import editIcon from "../assets/edit_FILL0_wght400_GRAD0_opsz48.svg";
import deleteIcon from "../assets/delete_FILL0_wght400_GRAD0_opsz48.svg";
import visibilityIcon from "../assets/visibility_FILL0_wght300_GRAD0_opsz48.svg";
import arrowUpIcon from "../assets/expand_circle_down_FILL0_wght300_GRAD0_opsz48.svg";
import arrowDownIcon from "../assets/expand_circle_down_FILL0_wght300_GRAD0_opsz48.svg";
import backArrowIcon from "../assets/navigate_before_FILL0_wght300_GRAD0_opsz48.svg";
import "./Requests.css";
import ConfirmDialog from "../components/ConfirmDialog";
import CloneConfirmDialog from "../components/CloneConfirmDialog";
import ShareOpportunity from "../components/ShareOpportunity";
import AddComment from "../components/AddComment";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SetAPIAccessToken from "../shared/axios-common";
import { parentDomain } from "../shared/Constants";
import { isParentDomain } from "../shared/Common";
export default function CollapsibleTable() {
  SetAPIAccessToken();
  const location = useLocation();
  const [rows, setRows] = React.useState<IProjectMappingData[]>([]);
  const [page, setPage] = React.useState(0);
  const [requestStatus, setRequestStatus] = React.useState(
    {} as OpenRequestStatusCount
  );

  const [resourceStatus, setResourceStatus] = React.useState(
    {} as OpenResourceRequest
  );
  const [filtersMasterData, setFiltersMasterData] =
    useState<IFilterDataDetails>({} as IFilterDataDetails);
  const [filterRequestData, setFilterRequestData] =
    useState<IFilterRequestData>(
      localStorage.getItem("filterReqData") == null
        ? ({} as IFilterRequestData)
        : (JSON.parse(
            localStorage.getItem("filterReqData") ?? "{}"
          ) as IFilterRequestData)
    );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openOpportunityCount, setOpenOpportunityCount] = useState(0);
  useEffect(() => {
    const filterReqData = filterRequestData as IFilterRequestData;
    if (location.state != undefined) {
      if (
        location.state.agreed_fulfillment_range != undefined &&
        location.state.agreed_fulfillment_range != ""
      ) {
        filterReqData.agreed_fulfillment_range =
          location.state.agreed_fulfillment_range;
      }
      if (location.state.status != undefined && location.state.status != "") {
        filterReqData.status = location.state.status;
      }

      if (
        location.state.resource_status != undefined &&
        location.state.resource_status != ""
      ) {
        filterReqData.resource_status = location.state.resource_status;
      }

      if (
        location.state.average_fulfillment_by_exp != undefined &&
        location.state.average_fulfillment_by_exp != ""
      ) {
        filterReqData.average_fulfillment_by_exp =
          location.state.average_fulfillment_by_exp;
      }

      if (
        location.state.average_fulfillment_by_skill != undefined &&
        location.state.average_fulfillment_by_skill != ""
      ) {
        filterReqData.average_fulfillment_by_skill =
          location.state.average_fulfillment_by_skill;
      }
      if (
        location.state.trend_analysis_months != undefined &&
        location.state.trend_analysis_months != ""
      ) {
        filterReqData.trend_analysis_months =
          location.state.trend_analysis_months;
      }
      if (
        location.state.filter_type != undefined &&
        location.state.filter_type != ""
      ) {
        filterReqData.filter_type = location.state.filter_type;
      } else {
        filterReqData.filter_type = "";
      }
      setFilterRequestData(filterReqData);
    }
    getFiltersData();
    getViewDetailsData(filterRequestData);
  }, []);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const ReloadCounts = () => {
    getOpenRequestStatus(filterRequestData);
    getOpenResourceStatus(filterRequestData);
  };

  function getViewDetailsData(filterRequestData: IFilterRequestData) {
    ProjectMappingDataService.getViewDetailsData(filterRequestData)
      .then((response: any) => {
        setRequestStatus(response.data.open_request_status);
        setResourceStatus(response.data.open_resource_request_status);
        setRows(response.data.request_details);
        var openOpportunity = 0;
        if (response.data.open_request_status?.status != null) {
          var openOpportunityCounts =
            response.data.open_request_status?.status.filter(
              (member: any) =>
                member.status == "NEW" ||
                member.status == "HIRING IN PROGRESS" ||
                member.status == "PARTIALLY FULFILLED"
            );
          for (var i = 0; i < openOpportunityCounts.length; i++) {
            openOpportunity = openOpportunity + openOpportunityCounts[i].count;
          }
        }
        setOpenOpportunityCount(openOpportunity);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  function getOpenRequestStatus(filterRequestData: IFilterRequestData) {
    ProjectMappingDataService.getRequestStatusDetails(filterRequestData)
      .then((response: any) => {
        setRequestStatus(response.data);
        var openOpportunity = 0;
        if (response.data != null) {
          var openOpportunityCounts = response.data?.status.filter(
            (member: any) =>
              member.status == "NEW" ||
              member.status == "HIRING IN PROGRESS" ||
              member.status == "PARTIALLY FULFILLED"
          );
          for (var i = 0; i < openOpportunityCounts.length; i++) {
            openOpportunity = openOpportunity + openOpportunityCounts[i].count;
          }
        }
        setOpenOpportunityCount(openOpportunity);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  function getOpenResourceStatus(filterRequestData: IFilterRequestData) {
    ProjectMappingDataService.getResourceStatusDetails(filterRequestData)
      .then((response: any) => {
        setResourceStatus(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  function getAll() {
    ProjectMappingDataService.getAll(filterRequestData)
      .then((response: any) => {
        setRows(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  function getFiltersData() {
    const filterReqData = {} as IFilterRequestData;
    filterReqData.userId = filterRequestData.userId;
    filterReqData.user_role = filterRequestData.user_role;
    filterReqData.status = filterRequestData.status;
    ProjectMappingDataService.getFilterData(filterReqData)
      .then((response: any) => {
        setFiltersMasterData(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  const ReloadData = () => {
    getAll();
  };

  const handleCallBackRequest = (event: any) => {
    const filterReqData = filterRequestData as IFilterRequestData;
    if (event != undefined) {
      if (event.target.id == "RequestTitle") {
        filterReqData.request_title = event.target.value;
      } else if (event.target.id == "ExperinceBand") {
        filterReqData.experience_band = event.target.value;
      } else if (event.target.id == "Skills") {
        filterReqData.skill = event.target.value;
      } else if (event.target.id == "Division") {
        filterReqData.business_unit = event.target.value;
      } else if (event.target.id == "Requester") {
        filterReqData.requester = event.target.value;
      } else if (event.target.id == "TrendAnalysisDuration") {
      } else if (event.target.id == "OppStatus") {
        filterReqData.status = event.target.value;
      }
      setFilterRequestData(filterReqData);
      localStorage.setItem("filterReqData", JSON.stringify(filterReqData));
      getViewDetailsData(filterRequestData);
    }
  };

  const handleClearFilter = (event: any) => {
    const filterReqData = filterRequestData as IFilterRequestData;
    filterReqData.request_title = null;
    filterReqData.experience_band = null;
    filterReqData.skill = null;
    filterReqData.business_unit = null;
    filterReqData.requester = null;
    filterReqData.status = filtersMasterData.request_status[0];
    setFilterRequestData(filterReqData);
    localStorage.setItem("filterReqData", JSON.stringify(filterReqData));
    getViewDetailsData(filterRequestData);
  };

  const opportunityStatusClick = (event: any) => {
    event.preventDefault();
    const filterReqData = filterRequestData as IFilterRequestData;
    if (event != undefined) {
      filterReqData.status = event.target.id;
      setFilterRequestData(filterReqData);
      localStorage.setItem("filterReqData", JSON.stringify(filterReqData));
      getViewDetailsData(filterRequestData);
    }
  };

  return (
    <div>
      <div className="row request-open-resource-outline">
        <div className="row padding-1">
          <Link to="/home">
            <div className="col-md-3" style={{ textAlign: "left" }}>
              <span>
                <img src={backArrowIcon} />
              </span>
              Back
            </div>
          </Link>
        </div>
        <div className="row border-bottom-box-shadow-1">
          <div className="col-md-6">
            <div className="col-md-1" style={{ textAlign: "center" }}>
              <span>
                <img
                  src={materialPeopleIcon}
                  title="Open Resource Requests icon"
                  alt="Open Resource Requests icon"
                ></img>
              </span>
            </div>
            <div className="col-md-11" style={{ textAlign: "left" }}>
              <h4 className="headings">
                {filterRequestData.status == "Open" && "Open"} Resource Requests
              </h4>
            </div>
          </div>
          <div className="col-md-6" style={{ textAlign: "right" }}>
            <h1 id="numbers">{resourceStatus?.open_resource_count} </h1>
          </div>
        </div>
        <div className="row">
          <Filter
            filtersMasterData={filtersMasterData}
            filterRequestDataCallback={handleCallBackRequest}
            clearFilter={handleClearFilter}
            filterReqData={filterRequestData}
            is_opp_status_required={true}
          />
        </div>
      </div>
      <div className="row open-requests">
        <div className="row padding-1">
          <div className="col-md-7" style={{ textAlign: "left" }}>
            <div className="col-md-1">
              <span>
                <img
                  src={openResourceIcon}
                  title="Open Requests icon"
                  alt="Open Requests icon"
                ></img>
              </span>
            </div>
            <div className="col-md-11">
              <h5 className="headings">
                {filterRequestData.status == "Open" && "Open"} Opportunities
              </h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6" style={{ margin: "1%" }}>
            <div className="row">
              <div
                className="col-md-2 center box-border-right open-resource-sadow-box"
                style={{ alignItems: "center" }}
              >
                <h1 id="numbers">{openOpportunityCount}</h1>
              </div>
              <div className="col-md-10 box-border-left open-resource-sadow-box">
                <div className="row center">
                  {requestStatus?.status?.length > 0 &&
                    requestStatus?.status
                      .filter(
                        (member) =>
                          member.status == "NEW" ||
                          member.status == "HIRING IN PROGRESS" ||
                          member.status == "PARTIALLY FULFILLED"
                      )
                      .map((status, index) => (
                        <div
                          className={
                            status.status == "PARTIALLY FULFILLED"
                              ? "col-md-12 open-request-header-margin"
                              : "col-md-6"
                          }
                        >
                          <div
                            id="open-resource"
                            className={
                              status.status == "PARTIALLY FULFILLED"
                                ? "open-resource-label center capitalize"
                                : "open-resource-label center margin-2 capitalize"
                            }
                          >
                            {status.status?.toLocaleLowerCase()}
                          </div>
                          <div className="open-resource-value open-resource-value-margin center">
                            <a
                              id={status.status}
                              onClick={opportunityStatusClick}
                              className="anchor"
                            >
                              {status.count}
                            </a>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5" style={{ margin: "1%" }}>
            <div className="row">
              <div className="col-md-12 open-resource-sadow-box">
                <div className="row center">
                  {requestStatus?.status?.length > 0 &&
                    requestStatus?.status
                      .filter(
                        (member) =>
                          member.status == "COMPLETELY FULFILLED" ||
                          member.status == "ON HOLD" ||
                          member.status == "CLOSED"
                      )
                      .map((status, index) => (
                        <div
                          className={
                            requestStatus?.status.filter(
                              (member) => member.status == "CLOSED"
                            ).length == 0 &&
                            (status.status == "COMPLETELY FULFILLED" ||
                              status.status == "ON HOLD")
                              ? "col-md-12 open-request-header-margin"
                              : requestStatus?.status.filter(
                                  (member) => member.status == "CLOSED"
                                ).length > 0 && status.status == "CLOSED"
                              ? "col-md-12 open-request-header-margin"
                              : "col-md-6"
                          }
                        >
                          <div
                            id="open-resource"
                            className={
                              requestStatus?.status.filter(
                                (member) => member.status == "CLOSED"
                              ).length > 0 && status.status == "CLOSED"
                                ? "open-resource-label center capitalize"
                                : requestStatus?.status.filter(
                                    (member) => member.status == "CLOSED"
                                  ).length == 0 &&
                                  (status.status == "COMPLETELY FULFILLED" ||
                                    status.status == "ON HOLD")
                                ? "open-resource-label center capitalize"
                                : "open-resource-label center margin-2 capitalize"
                            }
                          >
                            {status.status?.toLocaleLowerCase()}
                          </div>
                          <div className="open-resource-value open-resource-value-margin center">
                            <a
                              id={status.status}
                              onClick={opportunityStatusClick}
                              className="anchor"
                            >
                              {status.count}
                            </a>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
      </div>
      {rows.length > 0 && (
        <div className="collapsible-grid">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Opp Id</TableCell>
                  <TableCell style={{ width: "fit-content" }} align="center" />
                  <TableCell align="center">Opportunity Title</TableCell>
                  <TableCell align="center">ACN Lead</TableCell>
                  <TableCell align="center">Client Lead</TableCell>
                  <TableCell align="center">Division</TableCell>
                  <TableCell align="center">Created Date</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0 && rows.length > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row, i) => (
                  <Row
                    key={i + page * rowsPerPage + row.id}
                    order={i + page * rowsPerPage}
                    row={row}
                    reloadData={ReloadData}
                    filterRequestData={filterRequestData}
                    reloadCounts={ReloadCounts}
                  />
                ))}
              </TableBody>
            </Table>
            <TablePagination
              align="right"
              rowsPerPageOptions={[10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Results: ",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      )}
    </div>
  );
}

type RowProps = {
  order: number;
  row: IProjectMappingData;
  reloadData: () => void;
  filterRequestData: any;
  reloadCounts: () => void;
};

function Row(props: RowProps) {
  const formType = "Edit";
  const user_Id = localStorage.getItem("userId");
  const userDetails = user_Id != null ? user_Id : "";
  const isUserClient = !isParentDomain(userDetails, "@", parentDomain);
  const [request, setRequest] = useState(props.row);
  const [resource, setResource] = useState(props.row.resources);
  const [open, setOpen] = React.useState(false);
  const [datafeed, setData] = React.useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [AuditTrailModalShow, setAuditTrailModalShow] = React.useState(false);
  const [projectRequestsTimeLine, setProjectRequestsTimeLine] = React.useState(
    {} as IRequestDetailsHistoryData
  );
  const [modesmodel, setModesModel] = useState<GridRowModesModel>({});
  const [confirmModalShow, setConfirmModalShow] = React.useState(false);
  const [commentModalShow, setCommentModalShow] = React.useState(false);
  const [opportunityShareModalShow, setOpportunityShareModalShow] =
    React.useState(false);
  const [opportunityCloneModalShow, setOpportunityCloneModalShow] =
    React.useState(false);
  const [selectedid, setSelectedId] = React.useState({} as GridRowId);
  const handleHistoryClick = (id: GridRowId) => () => {
    ProjectMappingDataService.getRequestTimeline(id.toString())
      .then((response: any) => {
        setProjectRequestsTimeLine(response.data);
        setAuditTrailModalShow(true);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const addComment = (id: any, comments: any) => {
    const user_Id = localStorage.getItem("tblUserId");
    var comment = {
      id: id,
      comments: comments,
      created_by: user_Id != null ? parseInt(user_Id) : 0,
      comment_type: "opportunity",
    };
    ProjectMappingDataService.addComment(comment)
      .then((response: any) => {
        console.log("addComment call completed");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setRequest(props.row);
    setResource(props.row?.resources);
  }, [props.row, props.row?.resources]);
  const handleEditClick = (id: GridRowId) => () => {
    ProjectMappingDataService.get(id.toString(), {} as IFilterRequestData)
      .then((response: any) => {
        setData(response.data);
        setModalShow(true);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const handleDeleteClick = (id: GridRowId) => () => {
    setSelectedId(id);
    setConfirmModalShow(true);
  };

  const handleCloneClick = (id: GridRowId) => () => {
    setSelectedId(id);
    setOpportunityCloneModalShow(true);
  };

  const handleAddCommentClick = (id: GridRowId) => () => {
    setSelectedId(id);
    setCommentModalShow(true);
  };

  const handleOpportunityShareClick = (id: GridRowId) => () => {
    setSelectedId(id);
    setOpportunityShareModalShow(true);
  };

  const deletedRow = (id: GridRowId) => {
    ProjectMappingDataService.delete(id.toString())
      .then((response: any) => {
        props.reloadData();
        props.reloadCounts();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const cloneRow = (id: GridRowId) => {
    const user_Id = localStorage.getItem("tblUserId");
    const opportunityClone: IOpportunityCloneRequest = {
      created_by: user_Id != null ? parseInt(user_Id) : 0,
      opportunity_id: parseInt(id.toString()),
    };
    ProjectMappingDataService.opportunityClone(opportunityClone)
      .then((response: any) => {
        props.reloadData();
        props.reloadCounts();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const deleteResource = (id: GridRowId) => {
    let updatedResources: IResourceDetails[] = request.resources;
    let resourceAfterDelete = updatedResources.filter((x) => x.id != id);
    setRequest({ ...request, resources: resourceAfterDelete });
    props.reloadCounts();
  };
  const updateResource = (Row: IResourceDetails) => {
    let currentResources: IResourceDetails[] = request.resources;
    let updatedResources = currentResources.map((x) =>
      x.id === Row.id ? Row : x
    );
    setRequest({ ...request, resources: updatedResources });
    ResetRequestDetails();
    props.reloadCounts();
  };

  const ResetRequestDetails = () => {
    var propsData = props.filterRequestData;
    propsData.filter_type = "All";
    ProjectMappingDataService.get(request.id.toString(), propsData)
      .then((response: any) => {
        setRequest(response.data);
        setResource(response.data.resources);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const updateRequest = (updatedRequest: IProjectMappingData) => {
    ResetRequestDetails();
    props.reloadCounts();
    setOpen(false);
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ width: "fit-content" }} align="center">
          Opp-{request.id}
        </TableCell>
        <TableCell style={{ display: "table" }}>
          <button className="clickable-icons">
            <a
              className="textPrimary edit-icon"
              color="inherit"
              onClick={() => setOpen(!open)}
            >
              {open ? <img src={arrowUpIcon} /> : <img src={arrowDownIcon} />}
            </a>
          </button>
          <span> </span>
          <button className="clickable-icons">
            <a
              className="textPrimary"
              color="inherit"
              onClick={handleHistoryClick(request.id)}
            >
              <img src={visibilityIcon} />
            </a>
          </button>
        </TableCell>
        <TableCell component="th" scope="row">
          {request.request_title}
        </TableCell>
        <TableCell align="right">{request.acn_lead_name}</TableCell>
        <TableCell align="right">{request.client_lead_name}</TableCell>
        <TableCell align="right">{request.business_unit}</TableCell>
        <TableCell align="right">
          {request.created_on &&
            new Date(request.created_on)
              .toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
              .replace(/ /g, " ")}
        </TableCell>
        <TableCell align="right" className="statustextcapitalize">
          {request.status?.toLowerCase()}
        </TableCell>
        {!isUserClient ? (
          <TableCell align="right">
            <span> </span>
            <button className="clickable-icons">
              <a
                className="textPrimary"
                color="inherit"
                onClick={handleCloneClick(request.id)}
              >
                <span>
                  <ContentCopyIcon />
                </span>
              </a>
            </button>
            <button className="clickable-icons">
              <a
                className="textPrimary edit-icon"
                color="inherit"
                onClick={handleOpportunityShareClick(request.id)}
              >
                <span>
                  <ShareIcon />
                </span>
              </a>
            </button>
            <span> </span>
            <button className="clickable-icons">
              <a
                className="textPrimary edit-icon"
                color="inherit"
                onClick={handleEditClick(request.id)}
              >
                <img src={editIcon} />
              </a>
            </button>
            <span> </span>
            <button className="clickable-icons">
              {request.status != "CLOSED" ? (
                <a
                  className="textPrimary delicon"
                  color="inherit"
                  onClick={handleDeleteClick(request.id)}
                >
                  <img src={deleteIcon} />
                </a>
              ) : (
                <a className="disabled">
                  <img src={deleteIcon} />
                </a>
              )}
            </button>
            <span> </span>
            <button className="clickable-icons">
              <a
                className="textPrimary edit-icon"
                color="inherit"
                onClick={handleAddCommentClick(request.id)}
              >
                <span>
                  <AddCommentIcon />
                </span>
              </a>
            </button>
          </TableCell>
        ) : (
          <TableCell align="right">
            <button className="clickable-icons">
              <a
                className="textPrimary edit-icon"
                color="inherit"
                onClick={handleOpportunityShareClick(request.id)}
              >
                <span>
                  <ShareIcon />
                </span>
              </a>
            </button>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "white",
            height: "0px",
          }}
          colSpan={9}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{ minHeight: "100px" }}
          >
            <Box
              sx={{
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "grey",
                },
              }}
            >
              <div className="inline-grid">
                <Typography
                  style={{
                    width: "96%",
                    fontStyle: "Graphik",
                    fontSize: "14px/18px",
                    fontWeight: "500 !important",
                    color: "#000000",
                  }}
                  gutterBottom
                  component="div"
                >
                  Resources
                </Typography>
                <ResourceDataGrid
                  initialRows={resource}
                  initialModesModel={modesmodel}
                  requestId={request.id}
                  deleteResource={deleteResource}
                  updateResource={updateResource}
                  requestStatus={request.status}
                  ResetRequestDetails={ResetRequestDetails}
                ></ResourceDataGrid>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <AuditTrailProjectRequest
        projectRequestsTimeline={projectRequestsTimeLine}
        show={AuditTrailModalShow}
        onHide={() => setAuditTrailModalShow(false)}
        modesModel={modesmodel}
      />
      <ProjectMappingRequestModel
        datafeed={datafeed}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        modesModel={modesmodel}
        formType={formType}
        updateRequest={updateRequest}
      />
      <ConfirmDialog
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        deletedRow={deletedRow}
        selectedid={selectedid}
        title="Are you sure you want to delete this opportunity?"
      ></ConfirmDialog>

      <CloneConfirmDialog
        show={opportunityCloneModalShow}
        onHide={() => setOpportunityCloneModalShow(false)}
        cloneRow={cloneRow}
        selectedid={selectedid}
        title="Do you want to clone this opportunity?"
      ></CloneConfirmDialog>

      <AddComment
        show={commentModalShow}
        onHide={() => setCommentModalShow(false)}
        selectedid={selectedid}
        addComment={addComment}
        title="Add New Comment"
      ></AddComment>

      <ShareOpportunity
        show={opportunityShareModalShow}
        onHide={() => setOpportunityShareModalShow(false)}
        selectedid={selectedid}
        title="Share Opportunity"
      ></ShareOpportunity>
    </React.Fragment>
  );
}
