import React, { Component } from "react";
class Footer extends Component {
  render() {
    return (
      <div className="center">
        <span>
          Copyright 2001-2023 Accenture. All rights reserved. Accenture
          Confidential. For internal use only.
        </span>
      </div>
    );
  }
}
export default Footer;
