import { Modal } from "react-bootstrap";
import React, { useState } from "react";
const AddComment = (props: any) => {
  const [comment, setComment] = useState("");
  const handleChange = (event: any) => {
    setComment(event.target.value);
  };
  const modalClose = () => {
    props.onHide();
  };
  const handleAddCommentEvent = () => {
    props.addComment(props.selectedid, comment);
    setComment("");
    modalClose();
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {props.title}
        <div className="row savebtnrow">
          <textarea
            name="textValue"
            onChange={handleChange}
            placeholder="Type your comments"
            value={comment}
            className="textareaboxui"
          />
        </div>
        <div className="row center margin-2">
          <div className="col-md-2">
            <button
              type="submit"
              className="submit-button"
              id="delete"
              onClick={handleAddCommentEvent}
            >
              Save
            </button>
          </div>
          <div className="col-md-2">
            <button
              type="button"
              className="btn-secondary-color"
              onClick={modalClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddComment;
