import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { GridRowId, GridRowModel } from "@mui/x-data-grid";
import "./StatusChangeModal.css";
type StatusChangeProps = {
  show: boolean;
  onHide: () => void;
  updatedstatusAndComments: (
    newRow: GridRowModel,
    id: GridRowId,
    comments: string
  ) => void;
  statusModifiedRowDetails: GridRowModel;
  selectedStatusValue: string;
  selectedid: GridRowId;
};

const getCurrentDateInput = () => {
  const dateObj = new Date();
  // get the month in this format of 04, the same for months
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();
  const shortDate = `${year}-${month}-${day}`;
  return shortDate;
};

export default function StatusChangeModal(props: StatusChangeProps) {
  const [comment, setComment] = useState("");
  const handleChange = (event: any) => {
    setComment(event.target.value);
  };
  const modalClose = () => {
    props.onHide();
  };
  const handlesavecomment = () => {
    let modifiedRow = props.statusModifiedRowDetails;
    if (modifiedRow != null && modifiedRow != undefined) {
      modifiedRow.status = props.selectedStatusValue;
    }
    props.updatedstatusAndComments(modifiedRow, props.selectedid, comment);
    setComment("");
    modalClose();
  };

  const onChangeDate = (e: any) => {
    const newDate = new Date(e.target.value);
    let date =
      newDate.getFullYear() +
      "-" +
      (newDate.getMonth() + 1) +
      "-" +
      newDate.getDate();
    let modifiedRow = props.statusModifiedRowDetails;
    if (modifiedRow != null && modifiedRow != undefined) {
      modifiedRow.status_update_date = date;
    }
  };

  return (
    <Modal
      {...props}
      //size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="row" style={{ textAlign: "left" }}>
            <h5 className="headings">Reason for Status Change</h5>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginLeft: "2%", marginRight: "2%" }}>
          <div className="row">
            <div className="col-md-3">
              <input
                type="date"
                placeholder="Status Update Date"
                onChange={onChangeDate}
                required
                defaultValue={getCurrentDateInput()}
                className="datetextdetails"
              />
            </div>
          </div>
          <div className="row savebtnrow">
            <textarea
              name="textValue"
              onChange={handleChange}
              placeholder="Type your comments (optional)"
              value={comment}
              className="textareaboxui"
            />
          </div>

          <div className="row center margin-2">
            <div className="col-md-1">
              <button onClick={handlesavecomment} className="savebtncss">
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
