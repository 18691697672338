import React, { useState, useEffect } from "react";
import "./CreateRequest.css";
import { Form, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FullFeaturedCrudGrid from "./createrequesttablenew";
import {
  GridRowModel,
  GridRowModesModel,
  GridRowsProp,
} from "@mui/x-data-grid";
import IProjectMappingData, {
  IResourceDetails,
  IStatusHistoryDetails,
} from "../types/projectmapping.type";
import ProjectMappingDataService from "../services/projectmapping.service";
import getCurrentDate from "../shared/Common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { apiFormattedDate } from "../shared/DateFormatter";
import SetAPIAccessToken from "../shared/axios-common";
type ProjectMappingRequest = {
  initialData: IProjectMappingData;
  formType?: string;
  modalClose?: any;
  updateRequest?: (updatedRequest: IProjectMappingData) => void;
  title?: string | null;
};
const getCurrentDateInput = () => {
  const dateObj = new Date();
  // get the month in this format of 04, the same for months
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();
  const shortDate = `${year}-${month}-${day}`;
  return shortDate;
};

export default function CreateRequest(props: ProjectMappingRequest) {
  SetAPIAccessToken();
  const user_Id = localStorage.getItem("tblUserId");
  let navigate = useNavigate();
  if (props.initialData == null || props.initialData?.created_on == null) {
    props.initialData.status = "NEW";
    props.initialData.created_on = getCurrentDate().toString();
    const email = localStorage.getItem("userId");
    props.initialData.acn_lead_name = email != null ? email : "";
  }
  const [formData, setFormData] = useState(props.initialData);
  const [datafeed, setData] = useState<GridRowsProp>([]);
  const [modesmodel, setModesModel] = useState<GridRowModesModel>({});
  const [requestStatus, setRequestStatus] = useState<string>();

  type ErrorMessagesType = {acn_lead_name: null|string; client_lead_name: null|string}
  const [errorMessages, setErrorMessages] = useState<ErrorMessagesType>({acn_lead_name: null, client_lead_name: null});
  type ErrorMessagesColorsType = {acn_lead_name: string; client_lead_name: string}
  const [errorMessageColors, setErrorMessageColors] = useState<ErrorMessagesColorsType>({acn_lead_name: "", client_lead_name: ""});
  const UserTypes: { [key: string]: string } = {
    acn_lead_name: "ACNLead",
    client_lead_name: "ClientLead",
  };
  const ACNLead = "acn_lead_name";
  const ClientLead = "client_lead_name";
  
  const [finalResourceData, setFinalResourceData] = useState<
    IResourceDetails[]
  >(props.initialData.resources);
  useEffect(() => {
    if (props.initialData?.resources != null) {
      setData(props.initialData?.resources);
    }
  }, []);

  const validateEmail = (userType: string, input: string) => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
    
    if (userType === ACNLead) {
        if (isValidEmail && input.endsWith("accenture.com")) {
          return "";
        } else {
            return "Please enter a valid ACN Lead email address ending with 'accenture.com'.";
        }
    } else {
        if (isValidEmail && !input.endsWith("accenture.com")) {
          return "";
        } else {
            return "Please enter a valid Client Lead email address where domain is not 'accenture.com'.";
        }
    }
  };
  const checkUserExists = async (userType: string, data: any) => {
    const response = await ProjectMappingDataService.verifyUserExistence(data, userType);   
    if (!response)
      return("The user is not yet logged in to the tool. Please inform them to access.");
    else
      return "";
  };
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const showErrorMessage = (target : string, message : string|null, errorColor: string) => {
    const newErrorMessage: ErrorMessagesType = {
      ...errorMessages
    };   
    const newErrorMessageColor: ErrorMessagesColorsType ={
      ...errorMessageColors
    }; 
    if(target === ACNLead){
      newErrorMessage.acn_lead_name = message;
      newErrorMessageColor.acn_lead_name = errorColor;
    }
    else if (target === ClientLead){
      newErrorMessage.client_lead_name = message;
      newErrorMessageColor.client_lead_name = errorColor;
    }  
    setErrorMessages(newErrorMessage);
    setErrorMessageColors(newErrorMessageColor);
  }
  const acnClientHandleChange = async (event: React.FocusEvent<HTMLInputElement>) => {
    const userType = UserTypes[event.target.name];
    const emailExists = await checkUserExists(userType, event.target.value);
    const verifyEmail = validateEmail(event.target.name, event.target.value);
    if (verifyEmail !== "") { 
      showErrorMessage(event.target.name, verifyEmail, 'red');         
    }
    else if (emailExists !== "") {
      showErrorMessage(event.target.name, emailExists, 'blue');   
    }
    if(verifyEmail === "" && emailExists === ""){
      setErrorMessages({
        acn_lead_name: null,
        client_lead_name: null
      });
      setErrorMessageColors({
        acn_lead_name: "",
        client_lead_name: ""
      });
    }
  };

  const dropDownhandleChange = (event: any) => {
    setRequestStatus(event.target.value);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let statusHistory: IStatusHistoryDetails[] = [];
    statusHistory = [
      {
        id: 0,
        comments: "",
        created_user_id: user_Id != null ? parseInt(user_Id) : 0,
        status:
          requestStatus == null || requestStatus == undefined
            ? formData.status
            : requestStatus,
        created_on: formData.created_on,
        status_update_date: formData.created_on,
      },
    ];
    let currentStatus = "";
    if (requestStatus != undefined && requestStatus != null) {
      currentStatus = requestStatus;
    } else {
      currentStatus = formData.status;
    }
    if (
      props.formType == "Edit" &&
      props.initialData != null &&
      props.initialData.status == currentStatus
    ) {
      statusHistory = [];
    }
    finalResourceData.map((row) =>
      typeof row.id == "number" ? (row.id = row.id) : (row.id = 0)
    );

    const acnLeadValidationResult = validateEmail(ACNLead, formData.acn_lead_name);
    const clientLeadValidationResult = validateEmail( ClientLead, formData.client_lead_name);

    if (acnLeadValidationResult || clientLeadValidationResult) {
      toast.error("Invalid e-mail ids. Please validate the e-mail Ids ", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        toastId: "my_toast",
        className: "failedtoast",
      });
      return;
    }

    const CreateNewRequest: IProjectMappingData = {
      id: formData.id == null ? 0 : formData.id,
      request_title: formData.request_title,
      acn_lead_name: formData.acn_lead_name,
      client_lead_name: formData.client_lead_name,
      business_unit: formData.business_unit,
      status:
        requestStatus == null || requestStatus == undefined
          ? formData.status
          : requestStatus,
      created_on: formData.created_on,
      created_user_id: user_Id != null ? parseInt(user_Id) : 0,
      resources: finalResourceData,
      status_history: statusHistory,
    };

    if (CreateNewRequest.id == null || CreateNewRequest.id == 0) {
      ProjectMappingDataService.create(CreateNewRequest)
        .then((resp) => {
          toast.success("Request Submitted Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
            className: "successtoast",
          });
          setData([]);
          setModesModel({});
          setFormData({
            id: 0,
            acn_lead_name: "",
            request_title: "",
            client_lead_name: "",
            business_unit: "",
            status: "NEW",
            created_on: getCurrentDate().toString(),
            created_user_id: user_Id != null ? parseInt(user_Id) : 0,
            resources: [],
            status_history: [],
          });
          setTimeout(() => {
            navigate("/home");
          }, 3000);
        })
        .catch((resp) => {
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
            className: "failedtoast",
          });
          setData([]);
        });
    } else {
      ProjectMappingDataService.update(CreateNewRequest, CreateNewRequest.id)
        .then((resp) => {
          toast.success("Request Updated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
            className: "successtoast",
          });
          if (props.updateRequest != undefined) {
            props.updateRequest(CreateNewRequest);
          }
          if (props.modalClose != undefined) {
            props.modalClose();
          }
        })
        .catch((resp) => {
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
            className: "failedtoast",
          });
        });
      setFormData({
        ...formData,
        status:
          requestStatus == null || requestStatus == undefined
            ? formData.status
            : requestStatus,
      });
    }
  };

  const updateCollection = (
    dt: GridRowsProp,
    gridModel: GridRowModesModel,
    newRow: GridRowModel,
    comment: string
  ) => {
    //datafeed
    if (props.formType == "Edit") {
      setFinalResourceData(
        dt.map((item) => {
          let x = formData.resources.find((x) => x.id == item.id);
          if (x != undefined && x.status != item.status) {
            return {
              id: item.id,
              primary_skill: item?.primary_skill,
              resource_name: item?.resource_name,
              secondary_skills: item.secondary_skills,
              experience_band: item.experience_band,
              client_requested_date: apiFormattedDate(
                item.client_requested_date
              ),
              acn_agreed_fullfilment_date: apiFormattedDate(
                item.acn_agreed_fullfilment_date
              ),
              status: item.status,
              service_type: item.service_type,
              created_user_id: user_Id != null ? parseInt(user_Id) : 0,
              updated_user_id: user_Id != null ? parseInt(user_Id) : 0,
              created_on: apiFormattedDate(item.created_on),
              status_history: [
                {
                  id: 0,
                  comments: comment,
                  created_user_id: user_Id != null ? parseInt(user_Id) : 0,
                  status: item.status,
                  created_on: getCurrentDate().toString(),
                  status_update_date:
                    item.status_update_date == null
                      ? getCurrentDate().toString()
                      : apiFormattedDate(item.status_update_date),
                },
              ],
            } as IResourceDetails;
          } else if (x == undefined && newRow != undefined) {
            return {
              id: item.id,
              primary_skill: item?.primary_skill,
              resource_name: item?.resource_name,
              secondary_skills: item.secondary_skills,
              experience_band: item.experience_band,
              client_requested_date: apiFormattedDate(
                item.client_requested_date
              ),
              acn_agreed_fullfilment_date: apiFormattedDate(
                item.acn_agreed_fullfilment_date
              ),
              status: item.status,
              service_type: item.service_type,
              created_user_id: user_Id != null ? parseInt(user_Id) : 0,
              //This needs to be uncommented when going on live
              //created_on: getCurrentDate().toString(),
              //This needs to be remove when going on live
              created_on: apiFormattedDate(item.created_on),
              status_history: [
                {
                  id: 0,
                  comments: comment,
                  created_user_id: user_Id != null ? parseInt(user_Id) : 0,
                  status: item.status,
                  status_update_date:
                    item.status_update_date == null
                      ? getCurrentDate().toString()
                      : apiFormattedDate(item.status_update_date),
                  //This needs to be uncommented when going on live
                  //created_on: getCurrentDate().toString(),
                  //This needs to be remove when going on live
                  created_on: apiFormattedDate(item.created_on),
                },
              ],
            } as IResourceDetails;
          } else {
            return {
              id: item.id,
              primary_skill: item?.primary_skill,
              resource_name: item?.resource_name,
              secondary_skills: item.secondary_skills,
              experience_band: item.experience_band,
              client_requested_date: apiFormattedDate(
                item.client_requested_date
              ),
              acn_agreed_fullfilment_date: apiFormattedDate(
                item.acn_agreed_fullfilment_date
              ),
              status: item.status,
              service_type: item.service_type,
              created_user_id: user_Id != null ? parseInt(user_Id) : 0,
              //This needs to be uncommented when going on live
              //created_on: getCurrentDate().toString(),
              //This needs to be remove when going on live
              created_on: apiFormattedDate(item.created_on),
              status_history: [],
            } as IResourceDetails;
          }
        })
      );
    } else {
      setFinalResourceData(
        dt.map((item) => {
          return {
            id: item.id,
            primary_skill: item?.primary_skill,
            resource_name: item?.resource_name,
            secondary_skills: item.secondary_skills,
            experience_band: item.experience_band,
            client_requested_date: apiFormattedDate(item.client_requested_date),
            acn_agreed_fullfilment_date: apiFormattedDate(
              item.acn_agreed_fullfilment_date
            ),
            status: item.status,
            service_type: item.service_type,
            created_user_id: user_Id != null ? parseInt(user_Id) : 0,
            //This needs to be uncommented when going on live
            //created_on: getCurrentDate().toString(),
            //This needs to be remove when going on live
            created_on: apiFormattedDate(item.created_on),
            status_history: [
              {
                id: 0,
                comments: "",
                created_user_id: user_Id != null ? parseInt(user_Id) : 0,
                status: item.status,
                //This needs to be uncommented when going on live
                //created_on: getCurrentDate().toString(),
                //status_update_date: getCurrentDate().toString(),
                //This needs to be remove when going on live
                created_on: apiFormattedDate(item.created_on),
                status_update_date: apiFormattedDate(item.created_on),
              },
            ],
          } as IResourceDetails;
        })
      );
    }
  };

  const handleCloseClick = (event: any) => {
    props.modalClose();
  };

  const onChangeDate = (e: any) => {
    const newDate = new Date(e.target.value);
    let date =
      newDate.getFullYear() +
      "-" +
      (newDate.getMonth() + 1) +
      "-" +
      newDate.getDate();
    formData.created_on = date;
    setFormData(formData);
  };

  let opportunityStatus = ["ON HOLD", "CANCELLED"];
  return (
    <div style={{ padding: "2%" }}>
      {!props.title && (
        <div className="row create-request-title">New Opportunity</div>
      )}
      <div className="row">
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="toppadding">
            <Form.Label column sm={2} className="create-request-labels">
              Opportunity Title
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="request_title"
                value={formData.request_title}
                onChange={handleChange}
                placeholder="Opportunity Title"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="toppadding create-request-labels">
            <Form.Label column sm={2}>
              ACN Lead
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                type="text"
                name="acn_lead_name"
                value={formData.acn_lead_name}
                onChange={handleChange}
                onBlur={acnClientHandleChange}
                placeholder="Accenture Lead Email-Id"
                required
                aria-invalid={!errorMessages.acn_lead_name}
              />
              {
                errorMessages.acn_lead_name && 
                <Form.Label style={{ color: errorMessageColors.acn_lead_name, fontSize: 'small' }}> {errorMessages.acn_lead_name} </Form.Label>
              }
            </Col>
            <Form.Label column sm={2} className="create-request-labels">
               Division
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                type="text"
                name="business_unit"
                value={formData.business_unit}
                onChange={handleChange}
                placeholder="Division"
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="toppadding create-request-labels">
            <Form.Label column sm={2}>
              Client Lead
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                type="text"
                name="client_lead_name"
                value={formData.client_lead_name}
                onChange={handleChange}
                onBlur={acnClientHandleChange}
                placeholder="Client Lead Email-Id"
                required
                aria-invalid={!errorMessages.client_lead_name}
              />
              {
                errorMessages.client_lead_name && 
                <Form.Label style={{ color: errorMessageColors.client_lead_name, fontSize: 'small' }}> {errorMessages.client_lead_name} </Form.Label>
              }
            </Col>
            <Form.Label column sm={2} className="create-request-labels">
              Created On
            </Form.Label>
            <Col sm={4}>
              {/* The below CONDITION needs to be REMOVED when going on live */}
              {props.formType != "Edit" && (
                <Form.Control
                  type="date"
                  placeholder="Created On"
                  defaultValue={getCurrentDateInput()}
                  onChange={onChangeDate}
                />
              )}
              {props.formType == "Edit" && (
                <Form.Control
                  type="text"
                  placeholder="Created On"
                  value={moment(formData.created_on).format("DD-MMM-yyyy")}
                  readOnly
                  className="tesxtgreyout"
                />
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="toppadding create-request-labels">
            <Form.Label column sm={2}>
              Status
            </Form.Label>
            <Col sm={4}>
              {props.formType == "Edit" && formData.status != "CLOSED" ? (
                <Form.Select
                  className="form-control create-request-labels statustextcapitalize"
                  name="status"
                  value={
                    requestStatus == null || requestStatus == undefined
                      ? formData.status?.toLowerCase()
                      : requestStatus
                  }
                  onChange={dropDownhandleChange}
                >
                  <option
                    key={0}
                    className="statustextcapitalize"
                    value={formData.status}
                  >
                    {formData.status}
                  </option>
                  {opportunityStatus.map((item) => (
                    <option
                      className="statustextcapitalize"
                      key={item}
                      value={item}
                    >
                      {item.toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <Form.Control
                  type="string"
                  name="RequestStatus"
                  value={formData.status.toLowerCase()}
                  onChange={handleChange}
                  placeholder="Request Status"
                  readOnly
                  className="form-control tesxtgreyout create-request-labels statustextcapitalize"
                />
              )}
            </Col>
            <Form.Label column sm={2} className="create-request-labels">
              {props.formType == "Edit" && "Opp ID"}
            </Form.Label>
            <Col sm={4}>
              {props.formType == "Edit" && (
                <Form.Control
                  type="string"
                  name="ID"
                  value={"Opp" + formData.id}
                  placeholder="ID"
                  readOnly
                  className="form-control tesxtgreyout create-request-labels"
                />
              )}
            </Col>
          </Form.Group>

          <div className="toppadding">
            <FullFeaturedCrudGrid
              key={datafeed.length}
              initialRows={datafeed}
              updateCollection={updateCollection}
              initialModesModel={modesmodel}
              formType={props.formType}
              requestStatus={formData.status}
            />
          </div>
          {formData.status != "CLOSED" ? (
            props?.formType != undefined && props?.formType == "Edit" ? (
              <div className="row center margin-2">
                <div className="col-md-1">
                  <button type="submit" className="btn-primary-color">
                    Save
                  </button>
                </div>
                <div className="col-md-1">
                  <button
                    type="button"
                    className="btn-secondary-color"
                    onClick={handleCloseClick}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <Form.Group as={Row}>
                <Col className="toppadding" sm={{ offset: 5, span: 8 }}>
                  <button type="submit" className="submit-button">
                    Save
                  </button>
                </Col>
              </Form.Group>
            )
          ) : (
            <div className="row center margin-2">
              <div className="col-md-1">
                <button
                  type="button"
                  className="btn-secondary-color"
                  onClick={handleCloseClick}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </Form>
        <ToastContainer />
      </div>
    </div>
  );
}
