import React from "react";
import DropDownOptions from "./options";
type CustomDropdownProps = {
  optionsData: DropDownOptions[];
  selectedValue?: DropDownOptions | null;
  placeholder?: string | null;
  callBack: any;
  dropDownId: string;
  classNameValue?: string | null;
};

export default function CustomDropDown(props: CustomDropdownProps) {
  return (
    <div className="dropdown-select">
      <select
        className="form-select statustextcapitalize"
        value={props.selectedValue?.value}
        onChange={props.callBack}
        id={props.dropDownId}
      >
        {props.placeholder && <option value="">{props.placeholder}</option>}
        {props.optionsData != null &&
          props.optionsData.map((optionsData, i) =>
            props.classNameValue != "" && props.classNameValue != null ? (
              <option
                key={i}
                className={
                  props.classNameValue != "" && props.classNameValue != null
                    ? props.classNameValue
                    : "test"
                }
                value={optionsData.value}
              >
                {optionsData.label?.toLowerCase()}
              </option>
            ) : (
              <option key={i} value={optionsData.value}>
                {optionsData.label}
              </option>
            )
          )}
      </select>
    </div>
  );
}
