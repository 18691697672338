import * as React from "react";
import { IRequestTimelineData } from "../types/projectmapping.type";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import userProfileIcon from "../assets/group_FILL0_wght400_GRAD0_opsz48.svg";
import calenderIcon from "../assets/calendar_month_FILL0_wght400_GRAD0_opsz48 (1).svg";
import timeIcon from "../assets/schedule_FILL0_wght400_GRAD0_opsz48.svg";
function days_between(startDate: string, endDate: string) {
  if (endDate == new Date().toLocaleDateString()) return 0;
  var one_day = 1000 * 60 * 60 * 24;
  var result = (
    Math.round(new Date(endDate).getTime() - new Date(startDate).getTime()) /
    one_day
  ).toFixed(0);
  return result;
}

export default function RequestTimeLine(props: IRequestTimelineData | null) {
  return (
    <div>
      <div className="row request-audit-trail-box-shadow">
        <div
          className="history-request-timeline"
          style={{ margin: "5% !important" }}
        >
          {props?.title}
        </div>
      </div>
      <div
        className="row"
        style={{ paddingTop: "2% !important", opacity: "1" }}
      >
        <Timeline>
          {props?.status_history.map((statusHistory, index) => (
            <TimelineItem>
              <div className="col-md-2 center">
                <TimelineSeparator>
                  <TimelineDot>
                    {index == 0 && (
                      <div
                        className="capitalize"
                        style={{ backgroundColor: "#00F0A2" }}
                      >
                        {statusHistory.status?.toLocaleLowerCase()?.replace("e-hire","onboarding")}                        
                      </div>
                    )}
                    {index != 0 && (
                      <div
                        className="capitalize"
                        style={{ backgroundColor: "#DCB4FF" }}
                      >
                        {statusHistory.status?.toLocaleLowerCase()?.replace("e-hire","onboarding")}
                      </div>
                    )}
                  </TimelineDot>
                  {props?.status_history?.length - 1 != index && (
                    <TimelineConnector color="red" />
                  )}
                </TimelineSeparator>
              </div>

              <TimelineContent>
                <div
                  className="row"
                  style={{ marginBottom: "2%", marginTop: "-2%" }}
                >
                  <div className="col-md-5">
                    <div className="row">
                      <div className="col-md-2" style={{ textAlign: "center" }}>
                        <img src={userProfileIcon} />
                      </div>
                      <div
                        className="col-md-10 history-popup-user-values"
                        style={{ paddingTop: "3%" }}
                      >
                        { statusHistory.createdUser?.display_name ?? statusHistory.createdUser?.email_id }
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2" style={{ textAlign: "center" }}>
                        <img src={calenderIcon} />
                      </div>
                      <div
                        className="col-md-10 history-popup-user-values"
                        style={{ paddingTop: "3%" }}
                      >
                        {statusHistory.status_update_date &&
                          new Date(statusHistory.status_update_date)
                            .toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                            .replace(/ /g, " ")}
                      </div>
                    </div>
                    {statusHistory.status != "NOT FULFILLED" &&
                    statusHistory.status != "CANCELLED" &&
                    statusHistory.status != "CONTRACT SIGNED" &&
                    statusHistory.status != "CANCELLED" &&
                    statusHistory.status != "CLOSED" ? (
                      <div className="row">
                        <div
                          className="col-md-2"
                          style={{ textAlign: "center" }}
                        >
                          <img src={timeIcon} />
                        </div>
                        <div
                          className="col-md-10 history-popup-user-values"
                          style={{ paddingTop: "3%" }}
                        >
                          {statusHistory.status_update_date && index == 0 && (
                            <div>
                              {days_between(
                                statusHistory.status_update_date,
                                new Date().toLocaleDateString()
                              )}
                              &nbsp;days
                            </div>
                          )}

                          {statusHistory.status_update_date && index != 0 && (
                            <div>
                              {days_between(
                                statusHistory.status_update_date,
                                (props?.status_history[index - 1])
                                  .status_update_date ??
                                  new Date().toLocaleDateString()
                              )}
                              &nbsp;days
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-7">
                    {(statusHistory.comments != null &&
                      statusHistory.comments != "") ||
                    (statusHistory.status_comments != null &&
                      statusHistory.status_comments.length > 0) ? (
                      <div className="comments">
                        {" "}
                        {statusHistory.comments && (
                          <div className="border-bottom-box-shadow">
                            {statusHistory.comments}
                          </div>
                        )}
                        {statusHistory.status_comments &&
                          statusHistory.status_comments.map((item, index) =>
                            item.comment != null ? (
                              <div className="border-bottom-box-shadow">
                                <span>{item.comment}</span>
                                <br />
                                <span>
                                  <b>Commented By:</b>
                                  {item.createdUser?.display_name ?? item.createdUser?.email_id}
                                </span>
                                <br />
                                <span>
                                  <b>Commented On:</b>
                                  {item.created_on &&
                                    new Date(item.created_on)
                                      .toLocaleDateString("en-GB", {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      })
                                      .replace(/ /g, " ")}
                                </span>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </div>
  );
}
