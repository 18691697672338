import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Close";
import editIcon from "../assets/edit_FILL0_wght400_GRAD0_opsz48.svg";
import deleteIcon from "../assets/delete_FILL0_wght400_GRAD0_opsz48.svg";
import saveIcon from "../assets/save_FILL0_wght300_GRAD0_opsz48.svg";
import addIcon from "../assets/Icon ionic-ios-add-circle-outline.svg";
import "./createrequesttablenew.css";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import {
  IStatusHistoryDetails,
  IExperienceMasterData,
  IServiceTypeMasterData,
  IResourceStatusMasterData,
} from "../types/projectmapping.type";
import StatusChangeModal from "./StatusChangeModal";
import ProjectMappingDataService from "../services/projectmapping.service";
import ConfirmDialog from "../components/ConfirmDialog";
import getFormattedDate from "../shared/DateFormatter";
interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  requestStatus: string | undefined;
}

function EmptyToolbar(props: EditToolbarProps) {
  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={
          <a className="svg-icon" color="inherit">
            <img src={addIcon} />
          </a>
        }
        disabled={true}
        className="tesxtgreyout disabled"
      >
        Add record
      </Button>
    </GridToolbarContainer>
  );
}
function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id: id,
        primary_skill: "",
        resource_name: "",
        secondary_skills: "",
        experience_band: "",
        status: "NEW",
        service_type: "",
        status_history: [] as IStatusHistoryDetails[],
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={
          <a className="svg-icon" color="inherit">
            <img src={addIcon} />
          </a>
        }
        className={props.requestStatus == "CLOSED" ? "readOnly" : ""}
        onClick={handleClick}
      >
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

type CreateNewRequest = {
  initialRows: GridRowsProp;
  updateCollection: any;
  initialModesModel: GridRowModesModel;
  formType: string | undefined;
  requestStatus: string | undefined;
};

export default function FullFeaturedCrudGrid(props: CreateNewRequest) {
  const [rows, setRows] = React.useState(props.initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    props.initialModesModel
  );
  const [statusModifiedRow, setStatusModifiedRow] = React.useState(
    {} as GridRowModel
  );
  const [selectedid, setSelectedId] = React.useState({} as GridRowId);
  const [selectedStatusValue, setSelectedStatusValue] = React.useState(
    {} as string
  );
  const [statusChangeModalShow, setStatusChangeModalShow] =
    React.useState(false);

  const [experienceMasterData, setExperienceMasterData] = React.useState([]);
  const [serviceTypeMasterData, setServiceTypeMasterData] = React.useState([]);
  const [resourceStatusMasterData, setResourceStatusMasterData] =
    React.useState<IResourceStatusMasterData[]>([]);
  const [confirmModalShow, setConfirmModalShow] = React.useState(false);

  useEffect(() => {
    getExperienceMasterData();
    getServiceTypeMasterData();
    getResourceStatusMasterData();
  }, []);

  const getExperienceMasterData = () => {
    ProjectMappingDataService.getExperienceMasterData()
      .then((response: any) => {
        setExperienceMasterData(
          response.data.map((element: IExperienceMasterData) => element.band)
        );
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getServiceTypeMasterData = () => {
    ProjectMappingDataService.getServiceTypeMasterData()
      .then((response: any) => {
        setServiceTypeMasterData(
          response.data.map((element: IServiceTypeMasterData) => element.type)
        );
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getResourceStatusMasterData = () => {
    ProjectMappingDataService.getResourceStatusMasterData()
      .then((response: any) => {
        setResourceStatusMasterData(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const deletedRow = (id: GridRowId) => {
    setRows(rows.filter((row) => row.id !== id));
    props.updateCollection(
      rows.filter((row) => row.id !== id),
      rowModesModel
    );
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setSelectedId(id);
    setConfirmModalShow(true);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  function afterupdatecomplete() {}
  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    props.updateCollection(
      rows.map((row) => (row.id === newRow.id ? updatedRow : row)),
      rowModesModel,
      newRow,
      comments
    );
    return updatedRow;
  };

  let comments = "";
  const updatedstatusAndComments = (
    newRow: GridRowModel,
    id: GridRowId,
    comment: string
  ) => {
    comments = comment;
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    processRowUpdate(newRow);
  };
  const handleStatusChange = (
    event: any,
    id: GridRowId,
    rowdata: GridRowModel
  ) => {
    event.preventDefault();
    comments = "";
    console.log(id);
    setSelectedId(id);
    setSelectedStatusValue(event.target.value);
    setStatusModifiedRow(rowdata);
    setStatusChangeModalShow(true);
  };

  const columns: GridColumns = [
    {
      field: "slno",
      headerName: "Sl No.",
      type: "string",
      editable: true,
      width: 50,
      headerClassName: "datagridheadercolor",
    },
    {
      field: "primary_skill",
      headerName: "Primary Skill",
      type: "string",
      editable: true,
      headerClassName: "datagridheadercolor",
      flex: 1,
    },
    {
      field: "secondary_skills",
      headerName: "Secondary Skills",
      type: "string",
      editable: true,
      headerClassName: "datagridheadercolor",
      flex: 1,
    },
    {
      valueOptions: experienceMasterData,
      field: "experience_band",
      headerName: "Experience Band",
      type: "singleSelect",
      editable: true,
      headerClassName: "datagridheadercolor",
      flex: 1,
    },
    {
      valueOptions: serviceTypeMasterData,
      headerClassName: "datagridheadercolor",
      field: "service_type",
      headerName: "Service Type",
      type: "singleSelect",
      editable: true,
      flex: 1,
    },
    {
      field: "client_requested_date",
      headerName: "Requested Fulfillment Date",
      type: "date",
      editable: true,
      headerClassName: "datagridheadercolor",
      flex: 1,
      valueGetter: ({ value }) => getFormattedDate(value),
    },
    {
      field: "acn_agreed_fullfilment_date",
      headerName: "Agreed Fulfillment Date",
      type: "date",
      editable: true,
      headerClassName: "datagridheadercolor",
      flex: 1,
      valueGetter: ({ value }) => getFormattedDate(value),
    },
    {
      field: "actual_fullfilment_date",
      headerName: "Actual Fulfilled Date",
      type: "date",
      editable: false,
      headerClassName: "datagridheadercolor",
      flex: 1,
      valueGetter: ({ value }) => getFormattedDate(value),
    },
    {
      field: "created_on",
      headerName: "Creation Date",
      type: "date",
      editable: true,
      headerClassName: "datagridheadercolor",
      flex: 1,
      valueGetter: ({ value }) => getFormattedDate(value),
    },
    {
      field: "resource_name",
      headerName: "Resource Name",
      type: "string",
      editable: true,
      headerClassName: "datagridheadercolor",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      editable: false,
      headerClassName: "datagridheadercolor statustextcapitalize",
      flex: 1,
      renderCell: (params) => {
        if (props.formType == "Edit") {
          const isInEditMode =
            rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
          if (isInEditMode && !params.row.isNew) {
            return (
              <div className="dropdown-select">
                <select
                  value={params.row.status}
                  onChange={(event) =>
                    handleStatusChange(event, params.row.id, params.row)
                  }
                  className="requestselect statustextcapitalize"
                >
                  {resourceStatusMasterData &&
                    resourceStatusMasterData.map((optionsData, i) => (
                      <option
                        key={optionsData.status}
                        className="statustextcapitalize"
                        value={optionsData.status?.toString()}
                      >
                        {optionsData.status?.toLowerCase()?.replace("e-hire","onboarding")}
                      </option>
                    ))}
                </select>
              </div>
            );
          } else {
            return (
              <div className="statustextcapitalize">
                {params.row.status?.toLowerCase()?.replace("e-hire","onboarding")}
              </div>
            );
          }
        } else {
          return (
            <div className="statustextcapitalize">
              {params.row.status?.toLowerCase()}
            </div>
          );
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "datagridheadercolor",
      width: 75,
      cellClassName: "actions",
      renderHeader: () => null,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (props.requestStatus == "CLOSED") {
          return [
            <GridActionsCellItem
              icon={
                <a className="svg-icon" color="inherit">
                  <img src={editIcon} />
                </a>
              }
              label="Edit"
              className="tesxtgreyout disabled svg-icon"
              color="inherit"
              disabled={true}
            />,
            <GridActionsCellItem
              icon={
                <a className="svg-icon disabled svg-icon" color="inherit">
                  <img src={deleteIcon} />
                </a>
              }
              label="Delete"
              color="inherit"
              className="tesxtgreyout disabled svg-icon"
              disabled={true}
            />,
          ];
        }

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <a className="svg-icon" color="inherit">
                  <img src={saveIcon} />
                </a>
              }
              label="Save"
              className="svg-icon"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="svg-icon"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <a className="svg-icon" color="inherit">
                <img src={editIcon} />
              </a>
            }
            label="Edit"
            className="svg-icon"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <a className="svg-icon" color="inherit">
                <img src={deleteIcon} />
              </a>
            }
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            className="svg-icon"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        "& .datagridheadercolor": {
          backgroundColor: "lightgrey",
        },
        "& .odd": {
          backgroundColor: "#96968B40",
        },
      }}
    >
      <DataGrid
        sx={{
          tabSize: "100%",
          fontStyle: "Graphik",
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "500 !important",
            color: "#0A273C",
            font: "normal normal normal 13px/17px Graphik",
          },
          ".MuiDataGrid-virtualScrollerContent": {
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "normal",
              color: "#0A273C",
              font: "normal normal normal 13px/17px Graphik",
            },
          },
        }}
        rows={rows.map((item, index) => ({ slno: index + 1, ...item }))}
        autoHeight
        headerHeight={25}
        rowHeight={25}
        columns={columns}
        editMode="row"
        isCellEditable={(params) => {
          if (props.requestStatus == "CLOSED") return false;
          if (params.colDef.field == "status" && props.formType != "Edit")
            return false;
          else return true;
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: props.requestStatus != "CLOSED" ? EditToolbar : EmptyToolbar,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
      <StatusChangeModal
        show={statusChangeModalShow}
        onHide={() => setStatusChangeModalShow(false)}
        updatedstatusAndComments={updatedstatusAndComments}
        statusModifiedRowDetails={statusModifiedRow}
        selectedStatusValue={selectedStatusValue}
        selectedid={selectedid}
      />
      <ConfirmDialog
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        deletedRow={deletedRow}
        selectedid={selectedid}
        title="Are you sure you want to delete this resource?"
      ></ConfirmDialog>
    </Box>
  );
}
