function getCurrentDate() {
  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
  return date;
}

export function isParentDomain(input:string,splitBy:string,parentDomain:string):boolean{
let domainName=input.split(splitBy).pop();
if(domainName!=undefined && domainName.toLocaleLowerCase().includes(parentDomain)){
  return true;
}
else{
  return false;
}

}
export default getCurrentDate;
