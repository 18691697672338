function sideMenuSelect(path: any) {
  let selectedid = "";
  switch (path) {
    case "/" || "/home":
      selectedid = "home";
      break;
    case "/requests":
      selectedid = "home";
      break;
    case "/createrequest":
      selectedid = "createrequest";
      break;
    case "/delegation":
      selectedid = "delegation";
      break;
    default:
      selectedid = "home";
      break;
  }
  let divElement = document.getElementById(
    "sidebar-top-menu"
  ) as HTMLDivElement;
  if (divElement != null) {
    let subparentdiv = document.getElementById("nav-side-menu") as HTMLElement;
    if (subparentdiv != null) {
      let allmenus = subparentdiv.getElementsByClassName(
        "side-bar-menu"
      ) as HTMLCollectionOf<HTMLDivElement>;
      for (let i = 0; i < allmenus.length; i++) {
        if (allmenus[i].id == selectedid) {
          allmenus[i].classList.remove("not-active");
          allmenus[i].classList.add("active");
        } else {
          allmenus[i].classList.remove("active");
          allmenus[i].classList.add("not-active");
        }
      }
    }
  }
}
export default sideMenuSelect;
