import React from "react";
import DropDownOptions from "../shared/options";
import CustomDropDown from "../shared/CustomDropDown";
import filterIcon from "../assets/tune_FILL0_wght400_GRAD0_opsz48.svg";
export default function Filter(props: any) {
  let className = props.is_opp_status_required ? "col-md-1" : "col-md-2";
  return (
    <div className="row center filter">
      <div
        style={{ textAlign: "right", marginTop: "0.3%", width: "fit-content" }}
      >
        <span>
          <img src={filterIcon} title="Filter logo" alt="Filter logo"></img>
        </span>
      </div>
      <div className="col-md-2">
        <CustomDropDown
          optionsData={
            props.filtersMasterData?.request_title != null
              ? props.filtersMasterData?.request_title?.map((item: any) => {
                  return {
                    label: item,
                    value: item,
                  } as DropDownOptions;
                })
              : ([] as DropDownOptions[])
          }
          callBack={props.filterRequestDataCallback}
          placeholder="Opportunity Title"
          dropDownId="RequestTitle"
          selectedValue={
            props.filterReqData.request_title != null
              ? ({
                  label: props.filterReqData.request_title,
                  value: props.filterReqData.request_title,
                } as DropDownOptions)
              : ({
                  label: "",
                  value: "",
                } as DropDownOptions)
          }
        />
      </div>
      <div className="col-md-2">
        <CustomDropDown
          optionsData={
            props.filtersMasterData?.experience_band != null
              ? props.filtersMasterData?.experience_band?.map((item: any) => {
                  return {
                    label: item,
                    value: item,
                  } as DropDownOptions;
                })
              : ([] as DropDownOptions[])
          }
          placeholder="Experience Band"
          callBack={props.filterRequestDataCallback}
          dropDownId="ExperinceBand"
          selectedValue={
            props.filterReqData.experience_band != null
              ? ({
                  label: props.filterReqData.experience_band,
                  value: props.filterReqData.experience_band,
                } as DropDownOptions)
              : ({
                  label: "",
                  value: "",
                } as DropDownOptions)
          }
        />
      </div>
      <div className="col-md-2">
        <CustomDropDown
          optionsData={
            props.filtersMasterData?.skill != null
              ? props.filtersMasterData?.skill?.map((item: any) => {
                  return {
                    label: item,
                    value: item,
                  } as DropDownOptions;
                })
              : ([] as DropDownOptions[])
          }
          placeholder="Skills"
          callBack={props.filterRequestDataCallback}
          dropDownId="Skills"
          selectedValue={
            props.filterReqData.skill != null
              ? ({
                  label: props.filterReqData.skill,
                  value: props.filterReqData.skill,
                } as DropDownOptions)
              : ({
                  label: "",
                  value: "",
                } as DropDownOptions)
          }
        />
      </div>
      <div className="col-md-2">
        <CustomDropDown
          optionsData={
            props.filtersMasterData?.business_unit != null
              ? props.filtersMasterData?.business_unit?.map((item: any) => {
                  return {
                    label: item,
                    value: item,
                  } as DropDownOptions;
                })
              : ([] as DropDownOptions[])
          }
          placeholder="Division"
          callBack={props.filterRequestDataCallback}
          dropDownId="Division"
          selectedValue={
            props.filterReqData.business_unit != null
              ? ({
                  label: props.filterReqData.business_unit,
                  value: props.filterReqData.business_unit,
                } as DropDownOptions)
              : ({
                  label: "",
                  value: "",
                } as DropDownOptions)
          }
        />
      </div>
      <div className={className}>
        <CustomDropDown
          optionsData={
            props.filtersMasterData?.requester != null
              ? props.filtersMasterData?.requester?.map((item: any) => {
                  return {
                    label: item,
                    value: item,
                  } as DropDownOptions;
                })
              : ([] as DropDownOptions[])
          }
          selectedValue={
            props.filterReqData.requester != null
              ? ({
                  label: props.filterReqData.requester,
                  value: props.filterReqData.requester,
                } as DropDownOptions)
              : ({
                  label: "",
                  value: "",
                } as DropDownOptions)
          }
          placeholder="Requester"
          callBack={props.filterRequestDataCallback}
          dropDownId="Requester"
        />
      </div>
      {props.is_opp_status_required && (
        <div className={className}>
          <CustomDropDown
            optionsData={
              props.filtersMasterData?.request_status != null
                ? props.filtersMasterData?.request_status?.map((item: any) => {
                    return {
                      label: item,
                      value: item,
                    } as DropDownOptions;
                  })
                : ([] as DropDownOptions[])
            }
            selectedValue={
              props.filterReqData.status != null
                ? ({
                    label: props.filterReqData.status,
                    value: props.filterReqData.status,
                  } as DropDownOptions)
                : ({
                    label: "",
                    value: "",
                  } as DropDownOptions)
            }
            callBack={props.filterRequestDataCallback}
            dropDownId="OppStatus"
            classNameValue="statustextcapitalize"
          />
        </div>
      )}

      <div
        className="col-md-1 theme-color"
        style={{ textAlign: "left", marginTop: "0.5%" }}
      >
        <a className="anchor" onClick={props.clearFilter}>
          Clear Filter
        </a>
      </div>
    </div>
  );
}
