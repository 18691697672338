import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import "./shared/layout.css";
import React, { useState, useEffect } from "react";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import { GlobalLoading } from "react-global-loading";
import {
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { loginApiRequest, commonRequest } from "./services/authConfig";
import {
  AuthenticatedTemplate,
  useMsal,
  useAccount,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { IFilterRequestData } from "./types/projectmapping.type";
import ProjectMappingDataService from "./services/projectmapping.service";
import SetAPIAccessToken, { CreateAxios } from "./shared/axios-common";
function App() {
  CreateAxios();
  const [userRole, setUserRole] = useState(localStorage.getItem("user_role"));
  const [accessToken, setAccessToken] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("tblUserId"));
  const { login } = useMsalAuthentication(
    InteractionType.Silent,
    commonRequest
  );

  const { accounts, instance } = useMsal();
  if (accounts.length == 1) {
    const account = accounts[0];
    setLocalCacheData(account, null);
  } else if (accounts.length > 1) {
    accounts.forEach((account) => {
      let isSuperAdmin = setLocalCacheData(account, null);
      if (isSuperAdmin) return;
    });
  }
  const account = useAccount(accounts[0] || {});
  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: loginApiRequest.scopes,
          account: account,
        })
        .then((tokenResponse) => {
          // if (
          //   account.idTokenClaims?.roles != null &&
          //   account.idTokenClaims?.roles != undefined &&
          //   account.idTokenClaims?.roles?.length > 0
          // ) {
          //   var arrayName = account.idTokenClaims?.roles;
          //   for (var i = 0; i < arrayName?.length ?? 0; i++) {
          //     if (arrayName[i].toLowerCase() == "rfsuperuser") {
          //       setUserRole("Super Admin");
          //       break;
          //     } else {
          //       setUserRole("User");
          //     }
          //   }
          // } else {
          //   setUserRole("User");
          // }
          localStorage.setItem("accessToken", tokenResponse.accessToken);
          setAccessToken(tokenResponse.accessToken);
        });
    }
  }, [account, instance]);

  useEffect(() => {
    if (userRole) {
      const filterReq =
        localStorage.getItem("filterReqData") == null
          ? ({} as IFilterRequestData)
          : (JSON.parse(
              localStorage.getItem("filterReqData") ?? "{}"
            ) as IFilterRequestData);
      if (userRole != localStorage.getItem("user_role")) {
        localStorage.setItem("user_role", userRole);
        filterReq.user_role = userRole;
        localStorage.setItem("filterReqData", JSON.stringify(filterReq));
        window.location.reload();
      } else {
        localStorage.setItem("user_role", userRole);
        filterReq.user_role = userRole;
        localStorage.setItem("filterReqData", JSON.stringify(filterReq));
      }
    }
  }, [userRole]);

  useEffect(() => {
    if (accessToken) {
      const account = instance.getActiveAccount();
      if (account != null) {
        SetAPIAccessToken();
        let params = {
          email: account.username,
          password: "",
          display_name: account.name ?? account.username,
        };
        ProjectMappingDataService.upSertUser(params)
          .then(function (response: any) {            
            const filterReq =
              localStorage.getItem("filterReqData") == null
                ? ({} as IFilterRequestData)
                : (JSON.parse(
                    localStorage.getItem("filterReqData") ?? "{}"
                  ) as IFilterRequestData);
            filterReq.userId = response.data.user_id?.toString();
            localStorage.setItem("email", params.email);
            localStorage.setItem(
              "tblUserId",
              response.data.user_id?.toString()
            );
            localStorage.setItem("filterReqData", JSON.stringify(filterReq));
            setUserId(response.data.user_id?.toString());
            if (response.data.user_role?.toLowerCase() == "rfsuperuser") {
              setUserRole("Super Admin");
            } else {
              setUserRole("User");
            }
          })
          .catch(function (error: any) {
            console.log(error);
          });
      }
    }
  }, [accessToken]);

  instance
    .handleRedirectPromise()
    .then(() => {
      if (!account) {
        handleLogin();
      } else {
        localStorage.setItem("userId", account.username);
        localStorage.setItem("name", account.name ?? account.username);
      }
    })
    .catch((err) => {
      console.log(err);
    });

  instance.addEventCallback(async (event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      setLocalCacheData(account, null);
    } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      if (event.error?.name === "InteractionRequiredAuthError") {
        handleLogin();
      } else {
        console.log("ACQUIRE_TOKEN_FAILURE");
      }
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
      if (event.error?.name === "BrowserAuthError") {
        handleLogin();
      } else {
        console.log("LOGIN FAILURE");
      }
    } else {
      // console.log("Callback finished");
    }
  });

  function setLocalCacheData(account: any, graphResponse: any) {
    const filterReq =
      localStorage.getItem("filterReqData") == null
        ? ({} as IFilterRequestData)
        : (JSON.parse(
            localStorage.getItem("filterReqData") ?? "{}"
          ) as IFilterRequestData);
    localStorage.setItem("userId", account.username);
    localStorage.setItem("name", account.name ?? account.username);

    if (filterReq.user_role == "" || filterReq.user_role == null) {
      filterReq.user_role = "User";
    }
    localStorage.setItem("filterReqData", JSON.stringify(filterReq));
    instance.setActiveAccount(account);
    return localStorage.getItem("user_role") == "Super Admin";
  }

  function handleLogin() {
    localStorage.clear();
    login(InteractionType.Redirect, commonRequest);
  }

  let navbarComponent = <Navbar userRole={userRole} />;
  let sidebarComponent = <Sidebar />;
  let footerComponent = <Footer />;
  return (
    <div>
      <AuthenticatedTemplate>
        <div className="layout">
          <GlobalLoading />
          <div className="row">
            <div
              className="col-md-1 sidebar"
              style={{ backgroundColor: "#500c7c" }}
            ></div>
            <div className="col-md-11 data-container">{navbarComponent}</div>
          </div>
          <div className="row">
            <div
              className="col-md-1 sidebar center"
              style={{ backgroundColor: "#500c7c" }}
            >
              {sidebarComponent}
            </div>
            <div className="col-md-11 data-container">
              <div className="row main-content">
                {accessToken != "" && userId != "" && userId != null ? (
                  <AppRoutes />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-1 sidebar"
                style={{ backgroundColor: "#500c7c" }}
              ></div>
              <div className="col-md-11">{footerComponent}</div>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
    </div>
  );
}
export default App;
