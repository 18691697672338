import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ProjectMappingDataService from "../services/projectmapping.service";
import {
  IFilterDataDetails,
  IDashboardViewModel,
  IFilterRequestData,
  ITrendAnalysis,
  AverageFulfillmentRate,
} from "../types/projectmapping.type";
import DropDownOptions from "../shared/options";
import "./home.css";
import "react-multi-carousel/lib/styles.css";
import TrendAnalysis from "../components/TrendAnalysis";
import CustomDropDown from "../shared/CustomDropDown";
import Filter from "../components/Filter";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Carousel from "react-multi-carousel";
import BreachTimelineChart from "../components/BreachTimelineChart";
import trendAnalysisIcon from "../assets/process_chart_FILL0_wght300_GRAD0_opsz48.svg";
import filterIcon from "../assets/tune_FILL0_wght400_GRAD0_opsz48.svg";
import breachResourcesIcon from "../assets/timeline_FILL0_wght200_GRAD0_opsz48.svg";
import openResourceIcon from "../assets/groups_FILL0_wght200_GRAD0_opsz48.svg";
import averageFulfillmentIcon from "../assets/star_FILL0_wght400_GRAD0_opsz48.svg";
import eHireAvergaeIcon from "../assets/avg_time_FILL0_wght300_GRAD0_opsz48.svg";
import nextArrowIcon from "../assets/Path 85.svg";
import sideMenuSelect from "../shared/SideMenuSelect";
import SetAPIAccessToken from "../shared/axios-common";
import CollapsibleText from "./TrendAnalysisCollapsibletext";
import { averageFulfillmentRateInfoBox, onBoardingAverageInfo, onBoardingAverageInfoBox, openResourceRequirementsInfoBox, trendAnalysisInfoBox } from "../shared/Constants";
function getNumber(data: number | null | undefined) {
  if (data == null || data == undefined) {
    return 0;
  } else {
    return Math.round(data);
  }
}

export default function Home() {
  SetAPIAccessToken();
  const location = useLocation();
  const [byExperience, setByExperience] = useState(true);
  const [dashboardData, setDashboardData] = useState({} as IDashboardViewModel);
  const [filtersMasterData, setFiltersMasterData] =
    useState<IFilterDataDetails>({} as IFilterDataDetails);

  const [filterRequestData, setFilterRequestData] =
    useState<IFilterRequestData>(
      localStorage.getItem("filterReqData") == null
        ? ({} as IFilterRequestData)
        : (JSON.parse(
            localStorage.getItem("filterReqData") ?? "{}"
          ) as IFilterRequestData)
    );
  const [view, setView] = React.useState("Experience");
  const [trendAnalysisData, setTrendAnalysisData] = useState(
    [] as ITrendAnalysis[]
  );
  const [averageFulfillmentRate, setAverageFulfillmentRate] = useState(
    {} as AverageFulfillmentRate
  );

  const [ehireAverage, setEhireAverage] = useState(0);
  const [openResourceCount, setOpenResourceCount] = useState(0);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
    if (nextView == "Experience") setByExperience(true);
    else setByExperience(false);
  };

  const getDashboardData = (filterRequestData: IFilterRequestData) => {
    ProjectMappingDataService.getDashboardDetailsByFilter(filterRequestData)
      .then((response: any) => {
        setDashboardData(response.data);
        setTrendAnalysisData(response.data.trend_analysis);
        setEhireAverage(response.data.e_hire_average);
        setAverageFulfillmentRate(response.data.average_fulfillment_rate);
        var openResource = 0;
        if (response.data.open_resource_request?.status != null) {
          var openResourceCounts =
            response.data.open_resource_request?.status.filter(
              (member: any) =>
                member.status == "NEW" ||
                member.status == "IN PROGRESS" ||
                member.status == "RESOURCE IDENTIFIED" ||
                member.status == "NEEDS CLARIFICATION" ||
                member.status == "ON HOLD"
            );
          for (var i = 0; i < openResourceCounts.length; i++) {
            openResource = openResource + openResourceCounts[i].count;
          }
        }
        setOpenResourceCount(openResource);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getFilterMasterData = (filterRequestData: IFilterRequestData) => {
    const filterReqData = {} as IFilterRequestData;
    filterReqData.userId = filterRequestData.userId;
    filterReqData.user_role = filterRequestData.user_role;
    filterReqData.status = "All";
    ProjectMappingDataService.getFilterData(filterReqData)
      .then((response: any) => {
        setFiltersMasterData(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getTrendAnalysisData = (filterRequestData: IFilterRequestData) => {
    ProjectMappingDataService.getTrendAnalysisData(filterRequestData)
      .then((response: any) => {
        setTrendAnalysisData(response.data.trend_analysis);
        setEhireAverage(response.data.e_hire_average);
        setAverageFulfillmentRate(response.data.average_fulfillment_rate);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const filterReqData = filterRequestData as IFilterRequestData;
    filterReqData.agreed_fulfillment_range = "";
    filterReqData.status = "All";
    filterReqData.resource_status = "";
    filterReqData.opportunity_status = "";
    filterReqData.average_fulfillment_by_exp = "";
    filterReqData.average_fulfillment_by_skill = "";
    setFilterRequestData(filterReqData);
    getFilterMasterData(filterRequestData);
    getDashboardData(filterRequestData);
    const path = location.pathname;
    sideMenuSelect(path);
  }, []);

  const handleCallback = (event: any) => {
    const filterReqData = filterRequestData as IFilterRequestData;
    if (event != undefined) {
      if (event.target.id == "RequestTitle") {
        filterReqData.request_title = event.target.value;
      } else if (event.target.id == "ExperinceBand") {
        filterReqData.experience_band = event.target.value;
      } else if (event.target.id == "Skills") {
        filterReqData.skill = event.target.value;
      } else if (event.target.id == "Division") {
        filterReqData.business_unit = event.target.value;
      } else if (event.target.id == "Requester") {
        filterReqData.requester = event.target.value;
      } else if (event.target.id == "TrendAnalysisDuration") {
        filterReqData.trend_analysis_months = event.target.value;
      }
      localStorage.setItem("filterReqData", JSON.stringify(filterReqData));
      setFilterRequestData(filterReqData);
      if (event.target.id == "TrendAnalysisDuration") {
        getTrendAnalysisData(filterRequestData);
      } else {
        getDashboardData(filterRequestData);
      }
    }
  };

  const handleClearFilter = (event: any) => {
    const filterReqData = filterRequestData as IFilterRequestData;
    filterReqData.request_title = null;
    filterReqData.experience_band = null;
    filterReqData.skill = null;
    filterReqData.business_unit = null;
    filterReqData.requester = null;
    setFilterRequestData(filterReqData);
    localStorage.setItem("filterReqData", JSON.stringify(filterReqData));
    getDashboardData(filterRequestData);
  };

  return (
    <div>
      <div className="home-trend-analysis-outline">
        <Filter
          filtersMasterData={filtersMasterData}
          filterRequestDataCallback={handleCallback}
          clearFilter={handleClearFilter}
          filterReqData={filterRequestData}
        />
        <div className="row trend-analysis-chart">
          <div className="row padding-1 border-bottom-box-shadow">
            <div className="col-md-3" style={{ textAlign: "left" }}>
              <div className="col-md-2">
                <span>
                  <img
                    src={trendAnalysisIcon}
                    title="Trend analysis icon"
                    alt="Trend analysis icon"
                  ></img>
                </span>
              </div>
              <div className="col-md-10">
                <h4 className="headings">Trend Analysis</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-2" style={{ marginTop: "0.3%" }}>
                <span>
                  <img
                    src={filterIcon}
                    title="Filter logo"
                    alt="Filter logo"
                  ></img>
                </span>
              </div>
              <div className="col-md-10">
                <CustomDropDown
                  optionsData={
                    [
                      {
                        label: "12 Months",
                        value: "12",
                      } as DropDownOptions,
                      {
                        label: "6 Months",
                        value: "6",
                      } as DropDownOptions,
                      {
                        label: "3 Months",
                        value: "3",
                      } as DropDownOptions,
                    ] as DropDownOptions[]
                  }
                  selectedValue={
                    filterRequestData.trend_analysis_months != null
                      ? ({
                          label: filterRequestData.trend_analysis_months,
                          value: filterRequestData.trend_analysis_months,
                        } as DropDownOptions)
                      : ({
                          label: "6 Months",
                          value: "6",
                        } as DropDownOptions)
                  }
                  callBack={handleCallback}
                  dropDownId="TrendAnalysisDuration"
                />
              </div>
            </div>
            <div className="col-md-6" style={{ textAlign: "right" }}>
              <Link
                to="/requests"
                className="anchor"
                state={{
                  status: "All",
                  trend_analysis_months:
                    filterRequestData.trend_analysis_months != null
                      ? filterRequestData.trend_analysis_months
                      : 6,
                  filter_type: "TrendAnalysis",
                }}
              >
                View details{" "}
              </Link>
              <span>
                <img src={nextArrowIcon} />
              </span>
            </div>
          </div>
          <div className="row">
            <TrendAnalysis trendAnalysisData={trendAnalysisData} />
            <CollapsibleText>{trendAnalysisInfoBox}</CollapsibleText>
          </div>
        </div>

        <div className="row open-resource-requirments">
          <div className="row padding-1">
            <div className="col-md-7" style={{ textAlign: "left" }}>
              <div className="col-md-1">
                <span>
                  <img
                    src={openResourceIcon}
                    title="Open Resource Requirements icon"
                    alt="Open Resource Requirements icon"
                  ></img>
                </span>
              </div>
              <div className="col-md-11">
                <h4 className="headings">Open Resource Requirements</h4>
              </div>
            </div>
            <div className="col-md-5" style={{ textAlign: "right" }}>
              <Link
                to="/requests"
                className="anchor"
                state={{ status: "Open", filter_type: "OpenResources" }}
              >
                View details{" "}
              </Link>
              <span>
                <img src={nextArrowIcon} />
              </span>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-5 box-border-right"
              style={{ margin: "1% 0 1% 1%" }}
            >
              <div className="row">
                <div
                  className="col-md-3 center box-border-right open-resource-sadow-box"
                  style={{ alignItems: "center" }}
                >
                  <div className="row center">
                    <div className="col-md-12">Overall</div>
                    <div className="col-md-12">
                      <h1 id="numbers">{openResourceCount}</h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-9 box-border-left open-resource-sadow-box">
                  <div className="row" style={{ marginLeft: "1% 0% 1% 1%" }}>
                    By Status
                  </div>
                  <div className="row center">
                    {dashboardData.open_resource_request?.status &&
                      dashboardData.open_resource_request?.status
                        .filter(
                          (member) =>
                            member.status == "NEW" ||
                            member.status == "IN PROGRESS" ||
                            member.status == "RESOURCE IDENTIFIED"
                        )
                        .map((status, index) => (
                          <div
                            className={
                              status.status == "RESOURCE IDENTIFIED"
                                ? "col-md-12 open-resource-header-margin"
                                : "col-md-6"
                            }
                          >
                            <div
                              id="open-resource"
                              className={
                                status.status == "RESOURCE IDENTIFIED"
                                  ? "open-resource-label center capitalize"
                                  : "open-resource-label center margin-2 capitalize"
                              }
                            >
                              {status.status?.toLocaleLowerCase()}
                            </div>
                            <div className="open-resource-value open-resource-value-margin center">
                              <Link
                                to="/requests"
                                state={{
                                  resource_status: status.status,
                                  status: "Open",
                                }}
                                className="anchor"
                              >
                                {status.count}
                              </Link>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-2 box-border-left"
              style={{ margin: "1% 1% 1% 0%" }}
            >
              <div className="row open-resource-sadow-box">&nbsp;</div>
              <div className="row center open-resource-sadow-box">
                {dashboardData.open_resource_request?.status &&
                  dashboardData.open_resource_request?.status
                    .filter(
                      (member) =>
                        member.status == "NEEDS CLARIFICATION" ||
                        member.status == "ON HOLD"
                    )
                    .map((status, index) => (
                      <div className="col-md-12">
                        <div
                          id="open-resource"
                          className="open-resource-label center margin-2 capitalize"
                        >
                          {status.status?.toLocaleLowerCase()}
                        </div>
                        <div className="open-resource-value center margin-2">
                          <Link
                            to="/requests"
                            state={{
                              resource_status: status.status,
                              status: "Open",
                            }}
                            className="anchor"
                          >
                            {status.count}
                          </Link>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            <div className="col-md-4" style={{ margin: "1%" }}>
              <div className="row open-resource-sadow-box">&nbsp;</div>
              <div className="row center open-resource-sadow-box">
                {dashboardData.open_resource_request?.status &&
                  dashboardData.open_resource_request?.status
                    .filter(
                      (member) =>
                        member.status == "FULFILLED" ||
                        member.status == "E-HIRE INITIATED" ||
                        member.status == "E-HIRE COMPLETED" ||
                        member.status == "CONTRACT SENT"
                    )
                    .map((status, index) => (
                      <div className="col-md-6">
                        <div
                          id="open-resource"
                          className="open-resource-label center margin-2 capitalize"
                        >
                          {status.status?.toLocaleLowerCase()?.replace("e-hire","onboarding")}
                        </div>
                        <div className="open-resource-value center margin-2">
                          <Link
                            to="/requests"
                            state={{
                              resource_status: status.status,
                              status: "Open",
                            }}
                            className="anchor"
                          >
                            {status.count}
                          </Link>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <div className="row">            
            <CollapsibleText>{openResourceRequirementsInfoBox}</CollapsibleText>
          </div>
        </div>

        <div className="row resource-request-timeline-chart breach-timeline-intro">
          <div className="row padding-1">
            <div className="col-md-7" style={{ textAlign: "left" }}>
              <div className="col-md-1">
                <span>
                  <img
                    src={breachResourcesIcon}
                    title="Resources Request Breach Timeline icon"
                    alt="Resources Request Breach Timeline icon"
                  ></img>
                </span>
              </div>
              <div className="col-md-11">
                <h4 className="headings">Resources Request Breach Timeline</h4>
              </div>
            </div>
            <div className="col-md-5" style={{ textAlign: "right" }}>
              <Link
                to="/requests"
                className="anchor"
                state={{ status: "Open", filter_type: "BreachTimeline" }}
              >
                View details{" "}
              </Link>
              <span>
                <img src={nextArrowIcon} />
              </span>
            </div>
          </div>
          <div className="row">
            <BreachTimelineChart steps={dashboardData.breach_counts} />
          </div>
        </div>

        <div className="row home-trend-analysis-outline avg-rate-intro">
          <div className="row padding-1" style={{ textAlign: "left" }}>
            <div className="col-md-4">
              <div className="col-md-1">
                <span>
                  <img
                    src={averageFulfillmentIcon}
                    title="Average Fulfillment Rate icon"
                    alt="Average Fulfillment Rate icon"
                  ></img>
                </span>
              </div>
              <div className="col-md-11">
                <h4 className="headings">Average Fulfillment Rate</h4>
              </div>
            </div>
          </div>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-3 box-border-right">
              <div className="col-md-6">
                <ToggleButtonGroup
                  orientation="vertical"
                  value={view}
                  exclusive
                  onChange={handleChange}
                >
                  <ToggleButton value="Experience" aria-label="Experience">
                    Experience
                  </ToggleButton>
                  <ToggleButton value="By Skill" aria-label="Overall">
                    By Skill
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="col-md-6 center">
                <div className="row center">
                  <span>
                    <h2
                      style={{ textAlign: "center" }}
                      className="avergae-fulfillment-rate"
                    >
                      {getNumber(averageFulfillmentRate?.average_days)}
                    </h2>
                  </span>
                  <div style={{ textAlign: "center" }}>Working Days</div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              {byExperience && (
                <div>
                  <div className="row" style={{ marginLeft: "1%" }}>
                    By Experience
                  </div>
                  <div className="row">
                    {averageFulfillmentRate?.experience &&
                      averageFulfillmentRate?.experience.map(
                        (experience, index) => (
                          <div className="col-md-2" key={index}>
                            <div className="open-resource-label center margin-2">
                              {experience.experience}
                            </div>
                            <div className="open-resource-value center margin-2">
                              <Link
                                to="/requests"
                                state={{
                                  average_fulfillment_by_exp:
                                    experience.experience,
                                  status: "All",
                                  filter_type: "TrendAnalysis",
                                  trend_analysis_months:
                                    filterRequestData.trend_analysis_months !=
                                    null
                                      ? filterRequestData.trend_analysis_months
                                      : 6,
                                }}
                                className="anchor"
                              >
                                {getNumber(experience.count)} days
                              </Link>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                  <br />
                </div>
              )}
              {!byExperience && (
                <div>
                  <div className="row" style={{ marginLeft: "1%" }}>
                    By Skill
                  </div>
                  <div className="row">
                    <div>
                      <Carousel responsive={responsive}>
                        {averageFulfillmentRate?.skills &&
                          averageFulfillmentRate?.skills.map((skill, index) => (
                            <div
                              className="col-md-11"
                              style={{ margin: "1%" }}
                              key={index}
                            >
                              <div className="open-resource-label center margin-2">
                                {skill.skill}
                              </div>
                              <div className="open-resource-value center margin-2">
                                <Link
                                  to="/requests"
                                  state={{
                                    average_fulfillment_by_skill: skill.skill,
                                    status: "All",
                                    filter_type: "TrendAnalysis",
                                    trend_analysis_months:
                                      filterRequestData.trend_analysis_months !=
                                      null
                                        ? filterRequestData.trend_analysis_months
                                        : 6,
                                  }}
                                  className="anchor"
                                >
                                  {getNumber(skill.count)} days
                                </Link>
                              </div>
                            </div>
                          ))}
                      </Carousel>
                    </div>
                  </div>
                  <br />
                </div>
              )}
            </div>
          </div>
          <div className="row">            
            <CollapsibleText>{averageFulfillmentRateInfoBox}</CollapsibleText>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 padding-1 e-hire-average-css">
            <div className="row" style={{ textAlign: "left" }}>
              <div className="col-md-1">
                <span>
                  <img
                    src={eHireAvergaeIcon}
                    title="E-Hire Average icon"
                    alt="E-Hire Average icon"
                  ></img>
                </span>
              </div>
              <div className="col-md-11">
                <div className="headings">Onboarding Process Average</div>
              </div>
            </div>
            <div className="row center">
              <h1 id="numbers">{getNumber(ehireAverage)}</h1>
              Working Days
            </div>
            <div className="row center">              
              <CollapsibleText>{onBoardingAverageInfo}</CollapsibleText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
