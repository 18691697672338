import { Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import * as React from "react";
import RequestTimeLine from "../components/RequestTimeline";
import timeLineIcon from "../assets/timeline_FILL0_wght200_GRAD0_opsz48.svg";
export default function AuditTrailProjectRequest(props: any) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="row" style={{ textAlign: "left" }}>
            <div className="col-md-2">
              <span>
                <img
                  src={timeLineIcon}
                  title="Timeline icon"
                  alt="Timeline icon"
                ></img>
              </span>
            </div>
            <div className="col-md-10">
              <h5>
                <span className="request-audit_trail-label">
                  Opportunity Audit Trail{" "}
                </span>
              </h5>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div
            className="row"
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
          >
            <div className="row" style={{ paddingTop: "1%" }}>
              <div className="col-md-2 request-audit-popup-lables">
                <b>Request Title</b>
              </div>
              <div className="col-md-10">
                {props.projectRequestsTimeline?.request_title}
              </div>
            </div>
            <div className="row" style={{ paddingTop: "1%" }}>
              <div className="col-md-2 ">
                <b>ACN Lead</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.acn_lead_name}
              </div>
              <div className="col-md-2">
                <b>Fulfillment</b>
              </div>
              <div className="col-md-4">
                <div className="col-md-6">
                  <ProgressBar
                    now={props.projectRequestsTimeline?.fulfillment_rate}
                  />
                </div>
                <div className="col-md-6">
                  &nbsp;
                  {props.projectRequestsTimeline?.fulfillment_rate -
                    Math.floor(
                      props.projectRequestsTimeline?.fulfillment_rate
                    ) !==
                  0
                    ? props.projectRequestsTimeline?.fulfillment_rate?.toFixed(
                        2
                      )
                    : props.projectRequestsTimeline?.fulfillment_rate}
                  %
                </div>
              </div>
            </div>
            <div className="row" style={{ paddingTop: "1%" }}>
              <div className="col-md-2">
                <b>Client Lead</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.client_lead_name}
              </div>
              <div className="col-md-2">
                <b>Status</b> &nbsp; <span className="dot"></span>
              </div>
              <div className="col-md-4 capitalize">
                {props.projectRequestsTimeline?.status?.toLocaleLowerCase()}
              </div>
            </div>
            <div className="row" style={{ paddingTop: "1%" }}>
              <div className="col-md-2">
                <b>Division</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.business_unit}
              </div>
              <div className="col-md-2">
                <b>Created Date</b>
              </div>
              <div className="col-md-4">
                {props.projectRequestsTimeline?.created_on &&
                  new Date(props.projectRequestsTimeline?.created_on)
                    .toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                    .replace(/ /g, " ")}
              </div>
            </div>
          </div>
          <div className="request-audit_trail" style={{ marginTop: "2%" }}>
            <RequestTimeLine
              title="Request Timeline"
              status_history={props.projectRequestsTimeline?.status_history}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
